<template>
  <Layout
    header-class=""
    :show-newsletter="true"
    :show-cta="false"
    style="background-color: azure"
  >
    <div
      class="slider-area slider-style-1 height-850 bg_image media-img"
      :style="{
        'background-image': `url(${require(`@/assets/images/news.png`)})`,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <!-- <div class="react-image mb--20" data-aos="fade-up">
                  <img
                    src="../assets/images/demo/badge-vue-js.png"
                    alt="卓能科技"
                  />
                </div> -->
              <!-- <div>
                  <h3
                    class="rn-sub-badge"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <span class="theme-gradient"
                      >产品案例</span
                    >
                  </h3>
                </div> -->
              <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="color: black"
              >
                <!-- 新闻资讯 -->
              </h1>
              <h4
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-align: center;
                  color: black;
                  font-size: 24px;
                  margin-top: 50px;
                "
              >
                <!-- 聚焦行业动态，获取最新消息 -->
              </h4>

              <div
                class="button-group"
                data-aos="fade-up"
                data-aos-delay="200"
                @click="scrollToElement('demo')"
                style="margin-top: 300px; cursor: pointer"
              >
                <!-- <a class="btn-default btn-large round btn-icon" href="#demo" -->
                <a class="btn-default btn-large round btn-icon"
                  >查看更多
                  <Icon name="arrow-down" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
      "
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="行业资讯" name="first"></el-tab-pane>

        <el-tab-pane
          class="custom-tab-pane"
          label="卓能动态"
          name="second"
        ></el-tab-pane>
        <el-tab-pane label="政策法规" name="third"></el-tab-pane>
      </el-tabs>
    </div>

    <div
      id="demo"
      v-for="(item, index) in newsList"
      :key="index"
      v-if="newsList.length > 0"
    >
      <div
        class="active"
        @mouseenter="changeStyle('enter', $event)"
        @mouseleave="changeStyle('leave', $event)"
        @click="toDetail(item)"
      >
        <img
          width="250"
          height="150"
          :src="item.images == null ? `` : item.images[0].image"
          alt=""
        />
        <div class="news-content-container">
          <span class="news-content-newsName">{{ item.newsName }}</span>
          <span class="news-content-overview">{{ item.overview }}</span>
          <div
            class="detail"
            @mouseenter="changeStyle('detail-enter', $event)"
            @mouseleave="changeStyle('detail-leave', $event)"
          >
            查看详情
          </div>
        </div>
      </div>
    </div>

    <el-pagination
      align="center"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="sizeChange"
      v-if="total > 0"
    >
    </el-pagination>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";

import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";

import { listByNewsType } from "@/api/index";

export default {
  name: "News",
  components: {
    Team,
    Brand,
    Counter,
    Timeline,
    ServiceOne,
    AboutFour,
    SectionTitle,
    Separator,
    Layout,
    ContactForm,
    GoogleMap,
    Icon,
    Breadcrumb,
  },
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,

      newsList: [],
      activeName: "first",
      newsType: 1,

      richContent:
        '<p>这是一个<strong>富文本</strong>内容的例子。</p><img src="http://example.com/image.jpg" alt="示例图片">',
    };
  },
  methods: {
    changeStyle(type, e) {
      if (type == "enter") {
        e.currentTarget.className = "enter-active";
      } else if (type == "leave") {
        e.currentTarget.className = "active";
      } else if (type == "detail-enter") {
        e.currentTarget.className = "enter-detail";
      } else if (type == "detail-leave") {
        e.currentTarget.className = "detail";
      }
    },
    sizeChange(e) {
      console.log(e);
      this.pageNum = e;

      this.getListByNewsType();
    },
    getListByNewsType() {
      listByNewsType(this.newsType, this.pageNum, this.pageSize).then(
        (response) => {
          if (response.code == 200) {
            this.total = response.total;
            this.newsList = response.rows;
          }
        }
      );
    },
    handleClick(tab, event) {
      console.log(tab.index + 1);
      switch (tab.index) {
        case "0":
          this.newsType = 1;
          break;
        case "1":
          this.newsType = 2;

          break;
        case "2":
          this.newsType = 3;

          break;
      }
      this.pageNum = 1;
      this.getListByNewsType();
    },
    toDetail(item) {
      this.$router.push({ path: "/newsDetail", query: { item: item } });
    },
    scrollToElement(elementId) {
      const el = document.getElementById(elementId);
      if (el) {
        window.scrollTo({
          top: el.offsetTop,
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {
    // 动态创建link标签并添加到head中
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://unpkg.com/element-ui/lib/theme-chalk/index.css"; // 替换为你的CDN链接
    document.head.appendChild(link);

    this.getListByNewsType();
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .media-img {
  }
  .active {
    display: flex;
    width: 70%;
    background-color: white;

    margin: 50px auto;
    padding: 30px 50px;
    border-radius: 10px;
    cursor: pointer;
  }
  .enter-active {
    display: flex;
    width: 70%;
    margin: 50px auto;
    background-color: #f0f0f0;
    padding: 30px 50px;
    border-radius: 10px;
  }
  .enter-detail {
    background-color: #ffa100;
    text-align: center;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    width: 100px;
  }
  .detail {
    background-color: #d4d4d4;
    text-align: center;
    color: black;
    font-size: 16px;
    border-radius: 10px;
    width: 100px;
  }
  .news-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    justify-content: space-between;
  }
  .news-content-newsName {
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  .news-content-overview {
    color: black;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .media-img {
    display: none;
  }
  .active {
    display: flex;
    flex-direction: column;
    width: 70%;
    background-color: white;

    margin: 50px auto;
    padding: 30px 50px;
    border-radius: 10px;
  }
  .enter-active {
    cursor: pointer;
    display: flex;
    width: 70%;
    margin: 50px auto;
    background-color: #f0f0f0;
    padding: 30px 50px;
    border-radius: 10px;
  }
  .enter-detail {
    background-color: #ffa100;
    text-align: center;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    width: 100px;
  }
  .detail {
    background-color: #d4d4d4;
    text-align: center;
    color: black;
    font-size: 16px;
    border-radius: 10px;
    width: 100px;
    margin: 20px auto 0;
    display: none;
  }
  .news-content-container {
    display: flex;
    flex-direction: column;
    /* margin-left: 100px; */
    justify-content: space-between;
  }
  .news-content-newsName {
    color: black;
    font-weight: bold;
    font-size: 16px;
  }
  .news-content-overview {
    color: black;
    font-size: 14px;
    margin-top: 10px;
  }
}
</style>
