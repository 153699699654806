<template>
  <ul class="mainmenu">
    <!-- <li class="has-droupdown has-menu-child-item">
              <a href="#">
                  首页
                  <Icon name="plus" class="collapse-icon plus"/>
                  <Icon name="minus" class="collapse-icon minus"/>
              </a>
              
              <ul class="submenu">
                  <li><router-link to="/business-consulting">Business Consulting</router-link></li>
                  <li><router-link to="/business-consulting-2">Business Consulting 02</router-link></li>
                  <li><router-link to="/corporate">Corporate</router-link></li>
                  <li><router-link to="/business">Business</router-link></li>
                  <li><router-link to="/digital-agency">Digital Agency</router-link></li>
                  <li><router-link to="/finance">Finance</router-link></li>
                  <li><router-link to="/company">Company</router-link></li>
                  <li><router-link to="/marketing-agency">Marketing Agency</router-link></li>
                  <li><router-link to="/travel-agency">Travel Agency</router-link></li>
                  <li><router-link to="/consulting">Consulting</router-link></li>
                  <li><router-link to="/seo-agency">SEO Agency</router-link></li>
                  <li><router-link to="/personal-portfolio">Personal Portfolio</router-link></li>
                  <li><router-link to="/event-conference">Event Conference</router-link></li>
                  <li><router-link to="/creative-portfolio">Creative Portfolio</router-link></li>
                  <li><router-link to="/freelancer">Freelancer</router-link></li>
                  <li><router-link to="/international-consulting">International Consulting</router-link></li>
                  <li><router-link to="/startup">Startup</router-link></li>
                  <li><router-link to="/web-agency">Web Agency</router-link></li>
              </ul>
          </li>
           -->
    <li><router-link to="/">网站首页</router-link></li>
    <li class="with-megamenu has-menu-child-item">
      <a href="#">
        核心产品
        <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" />
      </a>
      <div class="rn-megamenu">
        <div class="wrapper">
          <div class="row" style="width: 100%">
            <div style="display: flex; flex-direction: row">
              <div class="product_container" style="">
                <span style="color: black; font-size: 30px; text-align: center"
                  >核心产品</span
                >
                <span
                  style="
                    color: black;
                    font-size: 14px;
                    text-align: center;
                    margin-top: 20px;
                  "
                  >打造标准化、规范化、高性能、可拓展的产品</span
                >
              </div>
              <div style="width: 70%; display: flex; flex-wrap: wrap">
                <div
                  class="pc-content"
                  v-for="(item, index) in productList"
                  :key="index"
                >
                  <!-- <img
                  :src="item.icon"
                  style="width: 18px; height: 18px; margin: auto 0"
                /> -->
                  <!-- <span
                  @click="change()"
                  style="margin-left: 10px; color: black; font-size: 14px; font-weight: bold;"
                  ><router-link
                    :to="{ name: 'Detail', query: { id: item.websiteProductId } }"
                    >{{ item.websiteProductName }}</router-link
                  ></span
                > -->
                  <span
                    @click="change(item.websiteProductId)"
                    style="
                      margin-left: 10px;
                      font-size: 14px;
                      font-weight: bold;
                      cursor: pointer;
                    "
                    class="default"
                    @mouseenter="changeStyle('enter', $event)"
                    @mouseleave="changeStyle('leave', $event)"
                    >{{ item.websiteProductName }}</span
                  >
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-6 single-mega-item">
                              <ul class="mega-menu-item">
                                  <li><router-link to="/finance">无人值守产品</router-link></li>
                                  <li><router-link to="/service">自动装车产品</router-link></li>
                                  <li><router-link to="/counter">车运管理产品</router-link></li>
                                  <li><router-link to="/progressbar">仓储管理产品</router-link></li>
                              </ul>
                          </div>
                          <div class="col-lg-6 single-mega-item">
                              <ul class="mega-menu-item">
                                  <li><router-link to="/team">网络控制器</router-link></li>
                                  <li><router-link to="/portfolio">车牌识别</router-link></li>
                                  <li><router-link to="/testimonial">led屏</router-link></li>
                                  <li><router-link to="/timeline">热敏打印机</router-link></li>
                                  <li><router-link to="/tab">针式打印机</router-link></li>
                                  <li><router-link to="/pricing">室外音柱</router-link></li>
                                  <li><router-link to="/spit-section">道闸</router-link></li>
                              </ul>
                          </div> -->
            <!-- <div class="col-lg-3 single-mega-item">
                              <ul class="mega-menu-item">
                                  <li><router-link to="/call-to-action">Call To Action</router-link></li>
                                  <li><router-link to="/video">Video</router-link></li>
                                  <li><router-link to="/gallery">Gallery</router-link></li>
                                  <li><router-link to="/contact">Contact</router-link></li>
                                  <li><router-link to="/brand">Brand</router-link></li>
                                  <li><router-link to="/portfolio">Portfolio</router-link></li>
                                  <li><router-link to="/error">404</router-link></li>
                              </ul>
                          </div>
                          <div class="col-lg-3 single-mega-item">
                              <ul class="mega-menu-item">
                                  <li>
                                      <router-link to="/advance-tab">
                                          Advance Tab <span class="rn-badge-card">Hot</span>
                                      </router-link>
                                  </li>
                                  <li>
                                      <router-link to="#">
                                          Brand Carousel <span class="rn-badge-card">Comming</span>
                                      </router-link>
                                  </li>
                                  <li>
                                      <router-link to="/advance-pricing">
                                          Advance Pricing <span class="rn-badge-card">Hot</span>
                                      </router-link>
                                  </li>
                                  <li>
                                      <router-link to="/portfolio-details/1">
                                          Portfolio Details
                                      </router-link>
                                  </li>
                                  <li>
                                      <router-link to="/blog-details/1">
                                          Blog Details
                                      </router-link>
                                  </li>
                              </ul>
                          </div> -->
          </div>
        </div>
      </div>
    </li>
    <!-- <li class="has-droupdown has-menu-child-item">
              <a href="#">
                  产品介绍
                  <Icon name="plus" class="collapse-icon plus"/>
                  <Icon name="minus" class="collapse-icon minus"/>
              </a>
              <ul class="submenu">
                  <li><router-link to="/blog-grid">无人值守产品</router-link></li>
                  <li><router-link to="/blog-grid-sidebar">自动装车产品</router-link></li>
                  <li><router-link to="/blog-list">车运管理产品</router-link></li>
                  <li><router-link to="/blog-list-sidebar">仓储管理产品</router-link></li>
              </ul>
          </li> -->
    <!-- <li class="has-droupdown has-menu-child-item">
              <a href="#">
                  会员服务
                  <Icon name="plus" class="collapse-icon plus"/>
                  <Icon name="minus" class="collapse-icon minus"/>
              </a>
              <ul class="submenu">
                  <li><router-link to="/portfolio">Portfolio Default</router-link></li>
                  <li><router-link to="/portfolio-three-column">Portfolio Three Column</router-link></li>
                  <li><router-link to="/portfolio-full-width">Portfolio Full Width</router-link></li>
                  <li><router-link to="/portfolio-grid-layout">Portfolio Grid Layout</router-link></li>
                  <li><router-link to="/portfolio-box-layout">Portfolio Box Layout</router-link></li>
              </ul>
          </li> -->
    <!-- <li><router-link to="/contact">联系我们</router-link></li> -->

    <li><router-link to="/gallery">项目案例</router-link></li>

    <li><router-link to="/news">新闻资讯</router-link></li>
    <li><router-link to="/services">支持与服务</router-link></li>

    <li><router-link to="/about">关于我们</router-link></li>
  </ul>
</template>

<script>
import Icon from "../../icon/Icon";
import { productListAll } from "@/api/index";
export default {
  name: "Nav",
  components: { Icon },
  data() {
    return {
      productList: [],
    };
  },
  methods: {
    getProductListAll() {
      productListAll().then((response) => {
        console.log("response.data");
        this.productList = response.data;
      });
    },
    change(id) {
      console.log(id);
      this.$router.push({ path: "/detail", query: { id: id } });

      if (this.$route.path === "/detail") {
        location.reload();
      }
    },

    changeStyle(type, e) {
      console.log(type);
      if (type == "enter") {
        e.currentTarget.className = "enter";
      } else if (type == "leave") {
        e.currentTarget.className = "leave";
      }
    },
  },
  mounted() {
    this.getProductListAll();
  },
};
</script>

<style scoped>
/* 媒体查询 */
@media (min-width: 768px) {
  /* PC样式 */
  .pc-content {
    width: 20%;
    display: flex;
    flex-direction: row;
    flex-wrap: row;
    padding: 10px;
    margin: 0 2.5%;
    justify-content: left;
  }
  .product_container {
    width: 30%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  /* 移动端样式 */
  .pc-content img {
    display: none;
  }
  .product_container {
    display: none;
  }
}

.default {
  color: black;
}
.enter {
  color: #0080ff;
}
.leave {
  color: black;
}
</style>
