<template>
  <Layout
    header-class=""
    :show-newsletter="true"
    :show-cta="false"
    style="background-color: azure"
  >
    <div
      v-show="false"
      class="slider-area slider-style-1 height-850 bg_image"
      :style="{
        'background-image': `url(${require(`@/assets/images/gallery01.png`)})`,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <!-- <div class="react-image mb--20" data-aos="fade-up">
                    <img
                      src="../assets/images/demo/badge-vue-js.png"
                      alt="卓能科技"
                    />
                  </div> -->
              <!-- <div>
                    <h3
                      class="rn-sub-badge"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <span class="theme-gradient"
                        >产品案例</span
                      >
                    </h3>
                  </div> -->
              <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="color: black"
              >
                新闻
              </h1>
              <h4
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-align: center;
                  color: black;
                  font-size: 24px;
                  margin-top: 50px;
                "
              >
                聚焦卓能动态，获取最新消息
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 70%; margin: 20px 15% 100px; display: flex; flex-direction: column; justify-content: center;">
      <h1 style="text-align: center">{{ item.newsName }}</h1>
      <img
        style="margin: 0 auto"
        :src="item.images == null ? `` : item.images[0].image"
        alt=""
      />

      <p v-html="item.newsContent"></p>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";

import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";

export default {
  name: "NewsDetail",
  components: {
    Team,
    Brand,
    Counter,
    Timeline,
    ServiceOne,
    AboutFour,
    SectionTitle,
    Separator,
    Layout,
    ContactForm,
    GoogleMap,
    Icon,
    Breadcrumb,
  },
  data() {
    return {
      item: {},
    };
  },
  methods: {},
  mounted() {
    console.log(this.$route.query.item);

    this.item = this.$route.query.item;
  },
};
</script>

<style scoped>
.image-slot {
  justify-content: center;
}
.center-icon {
  padding-top: 50px;
}
.active {
  display: flex;
  width: 70%;
  background-color: white;

  margin: 50px auto;
  padding: 30px 50px;
  border-radius: 10px;
}
.enter-active {
  display: flex;
  width: 70%;
  margin: 50px auto;
  background-color: #f0f0f0;
  padding: 30px 50px;
  border-radius: 10px;
}
.enter-detail {
  background-color: #ffa100;
  text-align: center;
  color: white;
  font-size: 16px;
  border-radius: 10px;
  width: 100px;
}
.detail {
  background-color: #d4d4d4;
  text-align: center;
  color: black;
  font-size: 16px;
  border-radius: 10px;
  width: 100px;
}
</style>
