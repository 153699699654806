<template>
  <Layout
    header-class="header-left-align"
    :show-newsletter="true"
    :show-cta="false"
  >
    <div
      class="slider-style-1 height-850 bg_image media-img"
      :style="{
        'background-image': `url(${detail.banner})`,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <!-- <div>
                <h3
                  class="rn-sub-badge"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <span class="theme-gradient"
                    ></span
                  >
                </h3>
              </div> -->
              <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <!-- 让客户获得更多的产品价值 -->
              </h1>

              <div
                class="button-group"
                data-aos="fade-up"
                data-aos-delay="200"
                @click="scrollToElement('demo')"
                style="margin-top: 300px; cursor: pointer"
              >
                <!-- <a class="btn-default btn-large round btn-icon" href="#demo" -->
                <a class="btn-default btn-large round btn-icon"
                  >查看更多
                  <Icon name="arrow-down" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" id="demo" style="margin-top: 50px">
      <div class="row row--30 align-items-center">
        <div style="padding: 0 0 20px">
          <div class="">
            <!-- <SectionTitle
              subtitle="功能"
              text-align="center"
              title="核心功能"
              data-aos="fade-up"
            /> -->
            <h4 style="text-align: center">系统概述</h4>
            <!-- <div style="height: 3px; width: 15%; background-color: #a0a0a0; margin: -5px auto 0;"></div> -->
          </div>
        </div>

        <div class="system_overview_container">
          <div class="system_overview_container_img">
            <img :src="detail.introduceImage" alt="" />
          </div>
          <div class="col-lg-7 mt_md--40 mt_sm--40" style="margin-left: 30px">
            <div class="content">
              <div class="">
                <div
                  data-aos="fade-up"
                  data-aos-delay="280"
                  style="
                    font-size: 16px;
                    text-indent: 3ch;
                    text-align: left;
                    line-height: 50px;
                  "
                >
                  {{ detail.introduce }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-5">
          <div class="thumbnail" data-aos="fade-up" data-aos-delay="250" >
            <img :src="detail.introduceImage" alt="" />
          </div>
        </div>
        <div class="col-lg-7 mt_md--40 mt_sm--40">
          <div class="content">
            <div class="">
              <h5
                class="title theme-gradient"
                data-aos="fade-up"
                data-aos-delay="280"
                style="font-size: 24px; text-indent: 3ch; text-align: left"
              >
                {{ detail.introduce }}
              </h5>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div v-show="id == 8 || id == 6 ? false : true">
      <div>
        <div style="padding: 100px 0 20px">
          <div class="col-lg-12">
            <!-- <SectionTitle
              subtitle="功能"
              text-align="center"
              title="核心功能"
              data-aos="fade-up"
            /> -->
            <h4 style="text-align: center">核心功能</h4>
          </div>
        </div>

        <div class="detail-container">
          <div
            v-for="(page, index) in detailList"
            :key="index"
            class="detail-item"
          >
            <div class="detail-item-container">
              <div class="col-lg-2" style="margin: auto 10px">
                <div
                  class="thumbnail"
                  data-aos="fade-up"
                  data-aos-delay="250"
                  style="perspective: 800px; /* 设置透视距离，增加3D效果 */"
                >
                  <img
                    :src="page.icon != null ? page.icon : ''"
                    alt=""
                    class="box"
                  />
                </div>
              </div>

              <div class="detail-item-div">
                <div>
                  <h6
                    style="font-weight: bold"
                    data-aos="fade-up"
                    data-aos-delay="280"
                  >
                    {{ page.funcName }}
                  </h6>
                </div>

                <div class="detail">
                  {{ page.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin: 50px 0 100px" v-if="id == 8">
      <h4 style="color: #f0871a; font-weight: bold; text-align: center">
        专业负责 只为成就客户
      </h4>
      <h4 style="color: #000000; font-weight: 700; text-align: center">
        需求分析+页面设计+代码开发+产品上线
      </h4>
      <div class="detail08_desc">
        <span
          ><span style="color: #8ec75a">山西卓能科技有限公司</span
          >，专注于软件定制开发(<span style="color: #5299d3"
            >APP开发 小程序开发 网站建设</span
          >)秉着“<span style="color: red">专业负责，只为成就客户</span
          >”的宗旨，服务过众多的企业，按时交付，按需定制以及完善的售后服务，用品质和专业的服务，深受客户的喜欢和认可。</span
        >
      </div>

      <div>
        <div style="padding-top: 50px">
          <div class="col-lg-12">
            <!-- <SectionTitle
              subtitle="功能"
              text-align="center"
              title="核心功能"
              data-aos="fade-up"
            /> -->
            <h4 style="text-align: center">服务内容</h4>
          </div>
        </div>
        <div class="detail08_service_content">
          <div
            class="detail08_service_content_item"
            v-for="(item, index) in contentArray"
            :key="index"
          >
            <img
              style="width: 50px; height: 55px; margin: 20px auto"
              :src="item.image"
              alt=""
            />
            <span
              style="
                text-align: center;
                margin-bottom: 20px;
                color: #53a3d2;
                font-size: 20px;
              "
              >{{ item.title }}</span
            >
            <div style="display: flex; flex-direction: row">
              <span style="margin-left: 30px">√&nbsp;</span>
              <div style="padding-right: 30px">
                <span class="detail08_service_content_item_text">{{
                  item.description1
                }}</span>
              </div>
            </div>

            <div style="display: flex; flex-direction: row">
              <span style="margin-left: 30px">√&nbsp;</span>
              <div style="padding-right: 30px">
                <span class="detail08_service_content_item_text">{{
                  item.description2
                }}</span>
              </div>
            </div>

            <div style="display: flex; flex-direction: row">
              <span style="margin-left: 30px">√&nbsp;</span>
              <div style="padding-right: 30px">
                <span class="detail08_service_content_item_text">{{
                  item.description3
                }}</span>
              </div>
            </div>

            <div style="display: flex; flex-direction: row">
              <span style="margin-left: 30px">√&nbsp;</span>
              <div style="padding-right: 30px">
                <span class="detail08_service_content_item_text">{{
                  item.description4
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div style="padding-top: 50px">
          <div class="col-lg-12">
            <!-- <SectionTitle
              subtitle="功能"
              text-align="center"
              title="核心功能"
              data-aos="fade-up"
            /> -->
            <h2 style="text-align: center">开发流程</h2>
          </div>
        </div>

        <div class="detail08_development_process">
          <img src="../assets/images/detail/flow.png" alt="" />
        </div>
      </div>

      <div v-show="false">
        <div style="padding-top: 150px">
          <div class="col-lg-12">
            <!-- <SectionTitle
              subtitle="功能"
              text-align="center"
              title="核心功能"
              data-aos="fade-up"
            /> -->
            <h2 style="text-align: center">服务保障</h2>
          </div>
        </div>

        <div
          style="
            width: 60%;
            margin: 50px 20%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              width: 50%;
              align-items: center;
              border-right: 1px solid #a0a0a0;
              border-bottom: 1px solid #a0a0a0;
              padding: 30px;
            "
          >
            <img
              style="width: 80px; height: 80px"
              src="../assets/images/detail/guarantee_icon1.png"
              alt=""
            />
            <div style="display: flex; flex-direction: column; margin: 0 50px">
              <span style="font-weight: bold; font-size: 20px; color: #000000"
                >产品需求预研</span
              >
              <span style="font-size: 14px"
                >从零到一配合客户需求分析、原型设计，全程和客户保持业务需求协作上的互动反馈。</span
              >
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              width: 50%;
              align-items: center;
              border-bottom: 1px solid #a0a0a0;
              padding: 30px;
            "
          >
            <img
              style="width: 80px; height: 80px"
              src="../assets/images/detail/guarantee_icon2.png"
              alt=""
            />
            <div style="display: flex; flex-direction: column; margin: 0 50px">
              <span style="font-weight: bold; font-size: 20px; color: #000000"
                >敏捷快速开发</span
              >
              <span style="font-size: 14px"
                >项目管理通过前后分离规范化代码实施，降低开发成本，超越竞争对手快人一步。</span
              >
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              width: 50%;
              align-items: center;
              border-right: 1px solid #a0a0a0;
              padding: 30px;
            "
          >
            <img
              style="width: 80px; height: 80px"
              src="../assets/images/detail/guarantee_icon3.png"
              alt=""
            />
            <div style="display: flex; flex-direction: column; margin: 0 50px">
              <span style="font-weight: bold; font-size: 20px; color: #000000"
                >保障交付使用</span
              >
              <span style="font-size: 14px"
                >从零到一配合客户需求分析、原型设计，全程和客户保持业务需求协作上的互动反馈。</span
              >
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              width: 50%;
              align-items: center;
              padding: 30px;
            "
          >
            <img
              style="width: 80px; height: 80px"
              src="../assets/images/detail/guarantee_icon4.png"
              alt=""
            />
            <div style="display: flex; flex-direction: column; margin: 0 50px">
              <span style="font-weight: bold; font-size: 20px; color: #000000"
                >持续升级维护</span
              >
              <span style="font-size: 14px"
                >产品交付后，按需保持维护和迭代新版本，持续提供技术和设计上的资询。</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin: 100px 0 100px" v-if="id == 6">
      <h4 style="text-align: center">可定制的的卓能OA管理系统</h4>
      <h4 style="text-align: center; padding: 0 0 20px">
        PC办公+移动办公的智能一体化办公平台
      </h4>
      <div class="detail06_oa">
        <div class="detail06_oa_item">
          <img
            style="width: 100%"
            src="../assets/images/oa/iwimg1.png"
            alt=""
          />
          <h6 style="text-align: center; margin: 20px 0; font-weight: 900">
            办公移动化
          </h6>
          <div style="text-indent: 1.8em; margin: 0 10px 20px">
            <span style="font-size: 14px"
              >卓能协同办公系统，支持移动办公以及通过手机、企业微信等随时随地进行申
              请和审批，碎片时间即可高效完成工作;事流程审批、CRM客户管理、通知公告、项目管理、任务管理、合同管理、企业知识等均支持移动端管理操作</span
            >
          </div>
        </div>

        <div class="detail06_oa_item">
          <img
            style="width: 100%"
            src="../assets/images/oa/iwimg2.png"
            alt=""
          />
          <h6 style="text-align: center; margin: 20px 0; font-weight: 900">
            协作扁平化
          </h6>
          <div style="text-indent: 1.8em; margin: 0 10px 20px">
            <span style="font-size: 14px"
              >卓能协同办公系统，帮助您打造扁平化工作平台，打破时间、地域的阻碍，信息跨终端推送;助您规范管理流程，减少管理层级，降低管理成本，提高工作效率，轻松实现数字化经营管理，提高企业互联网应用能力及企业竞争力</span
            >
          </div>
        </div>

        <div class="detail06_oa_item">
          <img
            style="width: 100%"
            src="../assets/images/oa/iwimg3.png"
            alt=""
          />
          <h6 style="text-align: center; margin: 20px 0; font-weight: 900">
            线上平台化
          </h6>
          <div style="text-indent: 1.8em; margin: 0 10px 20px">
            <span style="font-size: 14px"
              >卓能协同办公系统，集合企业各种管理功能为一体化解决方案，每个角色通过一个平台完成相应工作，高效协作，线上办公，只要有电脑或手机即可处理相关事务，无需纸质材料、高效协同，提升组织内部业务运营效率和经济效益</span
            >
          </div>
        </div>
      </div>

      <div style="margin-top: 100px">
        <h4 style="text-align: center; padding: 0 0 20px">产品功能模块</h4>
        <div class="detail06_oa_function">
          <div
            class="detail06_oa_function_item"
            v-for="(item, index) in oaModuleArray"
            :key="index"
          >
            <img
              class="detail06_oa_function_item_img"
              :src="item.image"
              alt=""
            />
            <div class="detail06_oa_function_item_title">{{ item.title }}</div>
          </div>
        </div>
      </div>

      <div style="margin-top: 100px">
        <h4 style="text-align: center">多角色的应用体验</h4>
        <h5 style="text-align: center; padding: 0 0 20px">
          (通过不同角色，体验OA带来的便捷、简单、高效等办公价值)
        </h5>

        <div class="detail06_oa_role_left">
          <img
            src="../assets/images/oa/role01.png"
            alt=""
            style="width: 358px; height: 196px"
          />
          <div class="detail06_oa_role_content">
            <h4 style="color: #1260a7">领导的一天</h4>
            <h5 style="color: #898481">
              通过不同角色，体验OA带来的便捷、简单、高效等办公价值。
            </h5>
            <div style="display: flex; justify-content: space-between">
              <div class="detail06_oa_role_content_text">通知公告</div>
              <div class="detail06_oa_role_content_text">智能审批</div>
              <div class="detail06_oa_role_content_text">产品管理</div>
              <div class="detail06_oa_role_content_text">项目管理</div>
              <div class="detail06_oa_role_content_text">企业知识</div>
            </div>
          </div>
        </div>

        <div class="detail06_oa_role_left">
          <div class="detail06_oa_role_content">
            <h4 style="color: #54b879">员工的一天</h4>
            <h5 style="color: #898481">
              简化操作界面，移动化协同办公，满足员工自助服务，实时高效的跨部门协助沟通。
            </h5>
            <div style="display: flex; justify-content: space-between">
              <div class="detail06_oa_role_content_text">通知公告</div>
              <div class="detail06_oa_role_content_text">工作汇报</div>
              <div class="detail06_oa_role_content_text">智能审批</div>
              <div class="detail06_oa_role_content_text">文件管理</div>
              <div class="detail06_oa_role_content_text">任务管理</div>
            </div>
          </div>
          <img
            src="../assets/images/oa/role02.png"
            alt=""
            style="width: 358px; height: 196px"
          />
        </div>

        <div class="detail06_oa_role_left">
          <img
            src="../assets/images/oa/role03.png"
            alt=""
            style="width: 358px; height: 196px"
          />
          <div class="detail06_oa_role_content">
            <h4 style="color: #dac520">销售的一天</h4>
            <h5 style="color: #898481">
              可视化销售管理，自动收集的公司市场情报，时刻掌握市场动向，提高销售效率。
            </h5>
            <div style="display: flex; justify-content: space-between">
              <div class="detail06_oa_role_content_text">客户管理</div>
              <div class="detail06_oa_role_content_text">业务订单</div>
              <div class="detail06_oa_role_content_text">合同管理</div>
              <div class="detail06_oa_role_content_text">项目管理</div>
              <div class="detail06_oa_role_content_text">企业知识</div>
            </div>
          </div>
        </div>

        <div class="detail06_oa_role_left">
          <div class="detail06_oa_role_content">
            <h4 style="color: #d22544">HR的一天</h4>
            <h5 style="color: #898481">
              优化人事管理流程，大幅节省了人员和设备投入，以及大限度提高员工参与度。
            </h5>
            <div style="display: flex; justify-content: space-between">
              <div class="detail06_oa_role_content_text">人力资源</div>
              <div class="detail06_oa_role_content_text">通知公告</div>
              <div class="detail06_oa_role_content_text">考勤打卡</div>
              <div class="detail06_oa_role_content_text">企业知识</div>
              <div class="detail06_oa_role_content_text">档案管理</div>
            </div>
          </div>
          <img
            src="../assets/images/oa/role04.png"
            alt=""
            style="width: 358px; height: 196px"
          />
        </div>
      </div>
    </div>

    <div style="margin: 50px 0 100px" v-if="id == 1">
      <div>
        <h4 style="text-align: center; padding: 0 0 20px">无人值守框架图</h4>
      </div>
      <div style="display: flex; justify-content: center">
        <img
          class="detail01_frame_diagram"
          src="../assets/images/detail/framework_wrzs.png"
          alt=""
        />
      </div>

      <h4 style="text-align: center; padding: 0 0 20px; margin-top: 30px">
        无人值守解决方案
      </h4>
      <div style="text-align: center; width: 90%; margin: 0 5%">
        场内作业流程从司机接单到场签到、排队进场，过一磅称重、核验点装卸货、过二磅最后验证出场的整个高效、自动化的全过程作业，具体流程如下:
      </div>
      <div style="width: 70%; margin: 50px 15% 100px">
        <div style="color: #6aa4d6; font-weight: bold; font-size: 20px">
          车辆进场
        </div>
        <div class="detail01_frame_diagram_item">
          <img
            class="detail01_frame_diagram_item_img"
            src="../assets/images/unattended/unattended01.png"
            alt=""
          />
          <div class="detail01_frame_diagram_item_content_container_left">
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
              "
            >
              <span
                >在矿场出入大门口安装大门口管控系统、车牌识别系统、道闸、车辆排队系统，提供车辆排队功能，
                根据车辆到达的先后顺序、入场车辆的数量管控优化车辆排队秩序，避免诸多不公正导致的排队纠纷。</span
              >
            </div>
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
                margin-top: 50px;
              "
            >
              <span
                >在矿场入口处根据车牌识别系统对车辆身份进行认证，分为内部员工车辆、社会预约车辆、拉运车辆，对于符合派车、合同、身份、入场条件等信息的车辆，道闸自动升起，允许车辆入场。</span
              >
            </div>
          </div>
        </div>
      </div>

      <div style="width: 70%; margin: 50px 15% 100px">
        <div
          style="
            color: #6aa4d6;
            font-weight: bold;
            font-size: 20px;
            text-align: right;
            width: 100%;
          "
        >
          车辆上磅
        </div>
        <div class="detail01_frame_diagram_item">
          <div class="detail01_frame_diagram_item_content_container_right">
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
              "
            >
              <span
                >车辆入场后根据语音提示为汽车指定汽车衡，通过车牌识别或扫码确认车牌和本次派车单运输相关认信息。当符合派车单的合法车辆到达一次过磅的道闸时，道闸自动升起，语音/LED自动提醒司机，开始车辆上磅。</span
              >
            </div>
          </div>
          <img
            class="detail01_frame_diagram_item_img"
            src="../assets/images/unattended/unattended02.png"
            alt=""
          />
        </div>
      </div>

      <div style="width: 70%; margin: 50px 15% 100px">
        <div style="color: #6aa4d6; font-weight: bold; font-size: 20px">
          车辆称重
        </div>
        <div class="detail01_frame_diagram_item">
          <img
            class="detail01_frame_diagram_item_img"
            src="../assets/images/unattended/unattended03.png"
            alt=""
          />
          <div class="detail01_frame_diagram_item_content_container_left">
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
              "
            >
              <span
                >车辆获取稳态:车辆上磅停止稳定后会有语音提示停车到位，前后道闸自动落下开始称重，并在数字显示屏上显示称重重量;</span
              >
            </div>
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
                margin-top: 10px;
              "
            >
              <span
                >称重开始:系统自动检测红外/雷达/激光测距/防干扰器等传感器的工作状态，当有干扰时，称重数据无效。</span
              >
            </div>
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
                margin-top: 10px;
              "
            >
              <span
                >称重完成:系统自动将称重数据记录下来，语音提示称重成功，道闸机抬起，红绿灯信号显示为绿灯，提示汽车可以下衡。如称重数据异常，司机可以点击取消，撤销此次称重。</span
              >
            </div>
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
                margin-top: 10px;
              "
            >
              <span
                >记录完成:绿灯亮起，显示屏视觉提示显示，道闸机视觉提示:抬起，音响语音提示。车辆下磅完成后，道闸自动落下，汽车衡恢复等待车辆入场状态。</span
              >
            </div>
          </div>
        </div>
      </div>

      <div style="width: 70%; margin: 50px 15% 100px">
        <div
          style="
            color: #6aa4d6;
            font-weight: bold;
            font-size: 20px;
            text-align: right;
            width: 100%;
          "
        >
          车辆下磅
        </div>
        <div class="detail01_frame_diagram_item">
          <div class="detail01_frame_diagram_item_content_container_right">
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
              "
            >
              <span
                >数据采集完毕后，后屏显示(采集信息:车牌号，吨数，允许下磅)，后灯(红变绿)，车辆完全下磅，地磅归零，前灯(红变绿)允许下一辆车上磅，后灯(绿变红)，后屏(默认信息若采集信息失败，后灯(红)，后屏显示相关错误信息。车辆自行下磅，或等待人工干预处理车辆放行下磅。</span
              >
            </div>
          </div>
          <img
            class="detail01_frame_diagram_item_img"
            src="../assets/images/unattended/unattended04.png"
            alt=""
          />
        </div>
      </div>

      <div style="width: 70%; margin: 50px 15% 100px">
        <div style="color: #6aa4d6; font-weight: bold; font-size: 20px">
          核验点装车
        </div>
        <div class="detail01_frame_diagram_item">
          <img
            class="detail01_frame_diagram_item_img"
            src="../assets/images/unattended/unattended05.png"
            alt=""
          />
          <div class="detail01_frame_diagram_item_content_container_left">
            <div
              style="
                text-indent: 2em;
                font-size: 15px;
                line-height: 30px;
                font-weight: 500;
              "
            >
              <span
                >在装煤核验点入口安装车牌识别系统、道闸与煤种核验系统，对已经完成一次过磅的车辆进行身份识别，同时车辆的拉运信息与当前核验点进行信息核对，对于符合身份的车辆道闸自动升起，允许车辆进入，对于不符合此次拉运信息的进行LED/语音提示。</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        margin: 100px 10% 100px;
        display: flex;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 0 10px 0 0;
      "
      v-if="id == 111"
    >
      <div style="display: flex; justify-content: center">
        <el-tabs
          tab-position="left"
          v-model="activeName"
          type="card"
          @tab-click="handleClick"
        >
          <el-tab-pane label="车牌自动识别模块" name="1"></el-tab-pane>

          <el-tab-pane
            class="custom-tab-pane"
            label="一次称重无人值守模块"
            name="2"
          ></el-tab-pane>
          <el-tab-pane label="二次称重无人值守模块" name="3"></el-tab-pane>
          <el-tab-pane label="无人值守交互验证打印模块" name="4"></el-tab-pane>
          <el-tab-pane label="无人值守交互显示提醒模块" name="5"></el-tab-pane>
          <el-tab-pane
            label="无人值守传感器故障诊断模块"
            name="6"
          ></el-tab-pane>
          <el-tab-pane label="无人值守监控模块" name="7"></el-tab-pane>
          <el-tab-pane
            label="无人值守道闸/红绿灯引导控制模块"
            name="8"
          ></el-tab-pane>
          <el-tab-pane
            label="无人值守红外/雷达/激光对射/防干扰模块"
            name="9"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <el-carousel
        style="
          width: 50%;
          border: 1px solid #ccc;
          margin: auto 0;
          border-radius: 10px;
        "
        type="card"
        indicator-position="none"
      >
        <el-carousel-item v-for="item in moduleArray.image" :key="item">
          <!-- <div style="height: 100%"> -->
          <!-- <img
              style="width: 100%; max-width: 100%; height: 80%; "
              :src="item"
              alt=""
            /> -->
          <el-image
            style="width: 100%; height: 100%"
            :src="item"
            :preview-src-list="moduleArray.image"
          >
          </el-image>
          <!-- </div> -->
        </el-carousel-item>
      </el-carousel>
      <p
        style="
          width: 60%;
          white-space: pre-line;
          line-height: 30px;
          color: #000000;
          font-size: 14px;
          margin-left: 5%;
        "
      >
        {{ moduleArray.content }}
      </p>
    </div>

    <div style="margin: 80px 0 100px" v-if="id == 3">
      <div>
        <h4 style="text-align: center; padding: 0 0 20px">
          卓能仓储管理系统架构
        </h4>
      </div>
      <div style="display: flex; justify-content: center">
        <img
          class="detail01_frame_diagram"
          src="../assets/images/detail/framework.png"
          alt=""
        />
      </div>

      <div style="margin-top: 80px">
        <h4 style="text-align: center; padding: 0 0 20px">制造业WMS仓库类型</h4>
      </div>

      <!--  -->

      <div class="detail03_container">
        <div class="detail03_container_item">
          <h5>原料库</h5>
          <div style="height: 100px">
            生产前企业原材料仓储管理，具有货品批次管理、灵活配置上架策略、拣货策略、库存预警策略等功能，支持地堆、高架、贯通式货架等多种仓库类型，支持多仓协同调拨，可有效支撑后续生产流程。
          </div>
          <img
            style="width: 100%; margin-top: 30px"
            src="../assets/images/detail/raw_material_warehouse.png"
            alt=""
          />
        </div>

        <div class="detail03_container_line_column"></div>

        <div class="detail03_container_item">
          <h5>成品库</h5>
          <div style="height: 100px">
            生产后仓储环节，成品的管理，通过成品入库打码、贴码，支持自定义编码规则，利用RFID智能收货、出库复核、智能盘点等新技术，有效提高作业效率，减少出错率，通过批次管理、策略配置，全程监控、库存可视，实现仓库精细化管理。
          </div>
          <img
            style="width: 100%; margin-top: 30px"
            src="../assets/images/detail/finished_product_warehouse.png"
            alt=""
          />
        </div>
      </div>

      <!--  -->
      <div class="detail03_container_line_row"></div>
      <!--  -->

      <div class="detail03_container">
        <div class="detail03_container_item">
          <h5>备件库</h5>
          <div style="height: 100px">
            企业自用备件、售后备件的管理，支持纸单作业和PDA作业，可实现对无码商品的管理，通过使用电子标签、灯光拣选、语音拣选等多种智能拣货方式，实现快速精准拣货，通过货品质检入库、分类上架，规范业务流程的同时提高作业准确率。
          </div>
          <img
            style="width: 100%; margin-top: 30px"
            src="../assets/images/detail/spare_parts_warehouse.png"
            alt=""
          />
        </div>

        <div class="detail03_container_line_column"></div>

        <div class="detail03_container_item">
          <h5>线边库</h5>
          <div style="height: 100px">
            生产车间线边仓管理，通过匹配生产订单，进行齐套决策、物料的自动扣账，提升线边仓库存的物料管理水平，打造面向智能制造的高效准时的生产物料供给体系。
          </div>
          <img
            style="width: 100%; margin-top: 30px"
            src="../assets/images/detail/line_edge_library.png"
            alt=""
          />
        </div>
      </div>

      <!--  -->

      <div>
        <h4 style="text-align: center; margin-top: 80px; padding: 0 0 20px">
          WMS仓储管理系统产品价值
        </h4>
      </div>

      <div style="display: flex; justify-content: center; margin-top: 30px">
        <img
          class="detail03_product_value"
          src="../assets/images/detail/product_value.png"
          alt=""
        />
      </div>

      <div class="detail03_product_value_bottom">
        <img
          class="detail03_product_value_bottom_img"
          src="../assets/images/detail/worth-pic1.png"
          alt=""
        />
        <div class="detail03_product_value_bottom_content">
          <h5 style="text-align: left">全程条码方案，仓库准确率提升到99+%</h5>
          <h6 style="text-align: left; line-height: 50px">
            卓能WMS仓储管理系统通过对仓库库位、作业单元、作业单据和指令的全条码化管理，实现实物流、单据流、数据流同步统一，
            库存更准确，物品可跟踪。
          </h6>
        </div>
      </div>

      <div class="detail03_product_value_bottom">
        <div class="detail03_product_value_bottom_content">
          <h5 style="text-align: left">
            无缝整合TMS云，实现订单及供应链库存可视化
          </h5>
          <h6 style="text-align: left; margin-top: 30px; line-height: 50px">
            卓能WMS仓储管理系统无缝整合TMS云，实现订单库存可视化、可根据运输安排提前备货，缓解仓库压力，提升配送时效。
          </h6>
        </div>
        <img
          class="detail03_product_value_bottom_img"
          src="../assets/images/detail/worth-pic3.png"
          alt=""
        />
      </div>
    </div>

    <div style="margin: 80px 0 100px" v-if="id == 2">
      <div>
        <h4 style="text-align: center; padding: 0 0 20px">
          TMS运输管理系统框架
        </h4>
      </div>
      <div style="display: flex; justify-content: center">
        <img
          class="detail01_frame_diagram"
          src="../assets/images/detail/framework_tms.png"
          alt=""
        />
      </div>

      <div style="display: flex; flex-direction: column">
        <h4 style="text-align: center; padding: 0 0 20px; margin-top: 50px">
          收益价值
        </h4>
        <div class="detail02_income_value_container">
          <img src="../assets/images/detail/TMS1.png" alt="" width="500px" />
          <div class="detail02_income_value_content_top">
            <span style="font-size: 20px; color: #1260a7"
              >OMS订单中台，实现订单流程可视化</span
            >
            <span style="margin-top: 30px"
              >基于订单中台化管理，可连接ERP、电商等多渠道订单，为企业打造自有订单资源池，实现从订单接入、订单拆分与合并、任务分配和异常预警等全流程的管控和优化，快速提升订单处理能力。</span
            >
          </div>
        </div>

        <div class="detail02_income_value_container">
          <img src="../assets/images/detail/TMS2.png" alt="" width="500px" />
          <div class="detail02_income_value_content_top">
            <span style="font-size: 20px; color: #1260a7"
              >从发货到签收，运输全流程透明可视化</span
            >
            <span style="margin-top: 30px"
              >面向多场景的运输需求货主，通过招标、竞价、抢单、指派等多种委托方式，基于预设规则与约束条件，结合路径优化引擎，自动匹配车源，计划运输路线，物流全程运输执行监控可视化。</span
            >
          </div>
        </div>

        <div class="detail02_income_value_container">
          <img src="../assets/images/detail/TMS3.png" alt="" width="500px" />
          <div class="detail02_income_value_content_top">
            <span style="font-size: 20px; color: #1260a7">路由追踪</span>
            <span style="margin-top: 30px"
              >以NB-I0T窄带物联网技术，百分百精准定位追踪货物与车辆，实时上报位置、承运商、异常等信息解决长途运输层层分包信息断层问题，真正实现“一单到底”。</span
            >
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";
import Icon from "../components/icon/Icon";

import { getByWebsiteProductId } from "@/api/index";

export default {
  name: "Honor",
  components: {
    Team,
    Brand,
    Counter,
    Timeline,
    ServiceOne,
    AboutFour,
    SectionTitle,
    Separator,
    Layout,
    Icon,
  },
  data() {
    return {
      detail: {},
      detailList: [],
      id: "",

      contentArray: [
        {
          image: require("../assets/images/detail/sContent_item_icon1.png"),
          title: "行业软件系统定制开发",
          description1: "一站式快速开发工程管理软件",
          description2: "为企业量身定制专属工程开发",
          description3: "个性化配置满足企业需求",
          description4: "功能强大支持智能场景联动",
        },
        {
          image: require("../assets/images/detail/sContent_item_icon2.png"),
          title: "工程项目管理系统解决方案",
          description1: "实现安全、质量、进度、人员、设备、物资的全流程管理",
          description2: "快速接入施工现场硬件数据",
          description3: "实现数据与软件的智能联动",
          description4: "为客户个性定制多种解决方案",
        },
        {
          image: require("../assets/images/detail/sContent_item_icon3.png"),
          title: "企业效率工具",
          description1: "为企业量身打造专属管理功能",
          description2: "实现人员、绩效等多维度管理",
          description3: "智能可视化图表展现,管理状况一目了然",
          description4: "功能强大支持智能场景联动",
        },
        {
          image: require("../assets/images/detail/sContent_item_icon4.png"),
          title: "移动应用开发",
          description1: "专业的移动应用设计与开发",
          description2: "提供全托管式APP上架服务",
          description3: "制作上架内容,缩短上传时间",
          description4: "开启APP消息推送功能",
        },
        {
          image: require("../assets/images/detail/sContent_item_icon5.png"),
          title: "微信小程序",
          description1: "快速小程序开发并上线企业专属技能,彰显企业特性",
          description2: "小程序界面布局,色调,交互方式,图标设计完美展现",
          description3: "适用于各种智能产品",
          description4: "开发周期快至3周",
        },
        {
          image: require("../assets/images/detail/sContent_item_icon6.png"),
          title: "信息系统建设",
          description1: "快速为企业建设系统,实现快捷高效的信息管理",
          description2: "为企业量身定制专属工程开发",
          description3: "适用于各种智能产品",
          description4: "开发周期快至3周",
        },
      ],

      oaModuleArray: [
        {
          image: require("../assets/images/oa/oa01.png"),
          title: "智 能 审 批",
        },
        {
          image: require("../assets/images/oa/oa02.png"),
          title: "客 户 管 理",
        },
        {
          image: require("../assets/images/oa/oa03.png"),
          title: "考 勤 打 卡",
        },
        {
          image: require("../assets/images/oa/oa04.png"),
          title: "工 作 汇 报",
        },
        {
          image: require("../assets/images/oa/oa05.png"),
          title: "人 力 资 源",
        },
        {
          image: require("../assets/images/oa/oa06.png"),
          title: "通 知 公 告",
        },
        {
          image: require("../assets/images/oa/oa07.png"),
          title: "产 品 管 理",
        },
        {
          image: require("../assets/images/oa/oa08.png"),
          title: "业 务 订 单",
        },
        {
          image: require("../assets/images/oa/oa09.png"),
          title: "合 同 管 理",
        },
        {
          image: require("../assets/images/oa/oa10.png"),
          title: "项 目 管 理",
        },
        {
          image: require("../assets/images/oa/oa11.png"),
          title: "任 务 管 理",
        },
        {
          image: require("../assets/images/oa/oa12.png"),
          title: "企 业 知 识",
        },
        {
          image: require("../assets/images/oa/oa13.png"),
          title: "文 件 管 理",
        },
        {
          image: require("../assets/images/oa/oa14.png"),
          title: "员 工 互 评",
        },
        {
          image: require("../assets/images/oa/oa15.png"),
          title: "员 工 关 怀",
        },
        {
          image: require("../assets/images/oa/oa16.png"),
          title: "车 辆 管 理",
        },
        {
          image: require("../assets/images/oa/oa17.png"),
          title: "会 议 管 理",
        },
        {
          image: require("../assets/images/oa/oa18.png"),
          title: "公 文 管 理",
        },
        {
          image: require("../assets/images/oa/oa19.png"),
          title: "档 案 管 理",
        },
        {
          image: require("../assets/images/oa/oa20.png"),
          title: "应 用 市 场",
        },
      ],

      activeName: "1",

      moduleArray: {
        image: [require("../assets/images/unattended/device01.jpg")],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "车牌识别系统是计算机视频图像识别技术在车辆牌照识别中的一种应用，目前，这一技术已经非常成熟并广泛应用于停车场、高速路口、收费通道等场所。在煤厂在使用的过程中，针对装煤的运输车辆以及现场环境的特殊性，针对车牌识别系统做出了针对性的优化方案：" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "针对入场车辆的大小型号的不同，我们在前端的摄像头安装了3组不同高度的高清网络摄像机，并根据现场的时间光照情况进行针对性自动补光，以确保车牌可以被准确清晰的检查到。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "针对运输车牌的清洁度较低的实际情况，我们在车牌识别的算法模块中特意针对性开发了，图像增强过滤算法，针对车牌进行精准识别。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "车牌识别模块的设备采用了嵌入式的系统，来确保模块长时间运行的稳定性。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "该系统模块在断网的情况下，依然支持离线工作，对已经传递过来的运输车辆信息进行离线比对，在网络恢复后进行信息续传。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "该模块与磅端端工控机端进行实时故障诊断，当发现异常时会主动提前提醒中心值守人员并在交互无人值守显示系统中实时显示工作状态进行维护。",
      },

      moduleArray01: {
        image: [require("../assets/images/unattended/device01.jpg")],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "车牌识别系统是计算机视频图像识别技术在车辆牌照识别中的一种应用，目前，这一技术已经非常成熟并广泛应用于停车场、高速路口、收费通道等场所。在煤厂在使用的过程中，针对装煤的运输车辆以及现场环境的特殊性，针对车牌识别系统做出了针对性的优化方案：" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "针对入场车辆的大小型号的不同，我们在前端的摄像头安装了3组不同高度的高清网络摄像机，并根据现场的时间光照情况进行针对性自动补光，以确保车牌可以被准确清晰的检查到。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "针对运输车牌的清洁度较低的实际情况，我们在车牌识别的算法模块中特意针对性开发了，图像增强过滤算法，针对车牌进行精准识别。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "车牌识别模块的设备采用了嵌入式的系统，来确保模块长时间运行的稳定性。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "该系统模块在断网的情况下，依然支持离线工作，对已经传递过来的运输车辆信息进行离线比对，在网络恢复后进行信息续传。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "该模块与磅端端工控机端进行实时故障诊断，当发现异常时会主动提前提醒中心值守人员并在交互无人值守显示系统中实时显示工作状态进行维护。",
      },

      moduleArray02: {
        image: [
          require("../assets/images/unattended/unattended01.png"),
          require("../assets/images/unattended/unattended02.png"),
          require("../assets/images/unattended/unattended03.png"),
          require("../assets/images/unattended/unattended04.png"),
          require("../assets/images/unattended/unattended05.png"),
        ],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "当执行采购协议车辆入场时，一次过磅为重车（毛重）数据。当执行售流协议车辆入场时，一次过磅端为空车（皮重）数据。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "1.车辆入场后根据语音提示为汽车指定汽车衡，通过车牌识别或扫码确认车牌和本次派车单运输相关认信息。当符合派车单的合法车辆到达一次过磅的道闸时，道闸自动升起，语音/LED自动提醒司机，开始车辆上磅。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "2.车辆上磅停止稳定后会有语音提示停车到位，前后道闸自动落下，开始称重，并在数字显示屏上显示称重重量；" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "3.称重开始时，系统自动检测红外/雷达/激光测距/防干扰器等传感器的工作状态，当有干扰时，称重数据无效。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "4.称重完成后，系统自动将称重数据记录下来，语音提示称重成功，道闸机抬起，红绿灯信号显示为绿灯，提示汽车可以下衡。如称重数据异常，司机可以点击取消，撤销此次称重。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "5.记录完成后，绿灯亮起，显示屏视觉提示显示，道闸机视觉提示:抬起，音响语音提示。车辆下磅完成后，道闸自动落下，汽车衡恢复等待车辆入场状态。",
      },

      moduleArray03: {
        image: [
          require("../assets/images/unattended/unattended01.png"),
          require("../assets/images/unattended/unattended02.png"),
          require("../assets/images/unattended/unattended03.png"),
          require("../assets/images/unattended/unattended04.png"),
          require("../assets/images/unattended/unattended05.png"),
        ],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "当执行采购协议车辆二次过磅为空车（皮重）数据。当执行售流协议车辆二次过磅端为重车（毛重）数据。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "1.在运煤汽车到达重车称重处，通过车牌识别或扫码确认车牌和本次派车单运输相关认信息。当符合派车单的合法车辆到达二次过磅的道闸时，道闸自动升起，语音/LED自动提醒司机，开始车辆上磅" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "2.车辆上磅停止稳定后会有语音提示停车到位，前后道闸自动落下，开始称重，并在数字显示屏上显示称重重量。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "3.称重开始时，系统自动检测红外/雷达/激光测距/防干扰器等传感器的工作状态，当有干扰时，称重数据无效。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "4.系统自动根据空车重量，去皮计算（满载车重-空车重=净重），确认此次的货重量；" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "5.称重完成后，系统自动将称重数据记录下来，语音提示称重成功，道闸机抬起，红绿灯信号显示为绿灯，提示汽车可以下衡。如称重数据异常，司机可以点击取消，撤销此次称重" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "6.司机如果对称重数据无异议，点击确认按钮，系统自动打票，记录完成后，绿灯亮起，显示屏视觉提示，道闸机视觉提示:抬起，音响语音提示。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "7.车辆下磅完成后，道闸自动落下，汽车衡恢复等待车辆入场状态。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "8.上传此次过磅相关数据到上级平台。",
      },

      moduleArray04: {
        image: [require("../assets/images/unattended/device01.png")],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "重车称重成功后，司机通过人脸识别或二维码，扫码后。司机确认本次工单任务的信息。通过自助打票和提取的功能。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "模块功能：" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "1、提供司机通过车牌识别自助过磅，司机过磅全程无需下车。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "2、供语音对讲功能，过磅司机可随时与调度中心沟通。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "3、提供LED提示功能，实现LED文字提示各类实时信息功能。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "4、提高语音播报功能，实时语音提醒司机车辆过磅时的各类信息。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "5、提供确认、取消按钮，可以处理各类异常情况，如超限、亏吨等。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "6、提供热敏打印机，支持一次，二次过磅时打印过磅单据。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "7、提供二维码扫码功能，确认司机、车辆拉运信息。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "8、可根据各类车型配置称重数据范围，最大毛重，最小皮重等数据，当仪表读数在配置的有效范围内才允许自动保存。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "9、支持多煤种等磅上加煤，在车辆一次过磅中，完成皮重，毛重等一次性过磅。",
      },

      moduleArray05: {
        image: [require("../assets/images/unattended/device02.png")],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "在车辆入场、上磅、称重、加煤（磅上筒仓）、下磅、离场各种状态时，实时提醒当前车辆过磅情况，直观表现磅端的各类状态。系统可以智能识别车辆频繁称重过磅或车辆超载、皮重过重等异常现象，并进行报警提示。系统提供语音提示/LED显示功能，减轻过磅工作量，提高沟通效率。",
      },

      moduleArray06: {
        image: [require("../assets/images/unattended/device01.png")],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "采用B/S架构设计，实时检测磅端各个物联网接入传感器的工作状态，出现异常情况时直接提醒工作人员。其中包括网络连接状态，车牌识别系统、摄像机、物联网控制器、红外/联网等设备工作状态。",
      },

      moduleArray07: {
        image: [require("../assets/images/unattended/device03.jpg")],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "在上磅口，车厢顶，车头部、下磅口，4处安装支持夜视红外的高清摄像机，并安装数字硬盘录像机，确保过磅车辆的全过程无死角进行不低于90天的视频录像存储，并在车辆一次，二次过磅时，实时采集车辆的前后照片，上传上级平台。系统提供视频自动抓拍功能，通过称重瞬间同时对每车抓拍称重图像，记录还原车辆在磅秤上的实际情况。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "（1）视频预览" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "对地磅的监控点有四处，入口，出口，车厢顶，车头部。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "（2）图片抓拍" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "车辆上磅后，待其处于稳定状态即称重时，可以对出入口及车厢顶部即前三个监控点进行图片抓拍。记录车辆过磅时的情况，有车辆信息、货物信息等。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "抓拍的图片，作为称重信息的一部分，存储在本地。供查询某条称重数据时，可以调出相应的图片。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "（3）车牌识别" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "车辆到达汽车衡前在入口处，通过车牌识别系统对车辆的合法性进行判断，符合的派车单的车辆开启道闸。",
      },

      moduleArray08: {
        image: [require("../assets/images/unattended/device04.jpg")],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "系统提供栏杆机指示功能，实现对车辆的前行指示功能，在入口处对于符合身份的车辆，自动开启道闸，红绿灯由红色变为绿色，当不符合身份的车辆，系统通过语音/LED进行提醒。在车辆上磅完成后，入口道闸自动关闭，红绿灯变红色。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "如该车辆时在磅上进行筒仓加煤时，通过系统进行设置是否允许筒仓加煤时开启道闸让车辆可以进行前后移动。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "在车辆称重完成后，出口处道闸自动开启，红绿灯由红色变为绿色，允许车辆下磅，当车辆下磅完成时，出口处道闸自动落下，红绿灯由绿色变为红色。",
      },

      moduleArray09: {
        image: [require("../assets/images/unattended/device03.jpg")],
        content:
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "在汽车衡的入口和出口处提供激光对射或雷达或激光，防止车辆在上磅、下磅、称重时不完全上磅造成的称重数据不准的损失。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "系统提供防干扰系统，防止其他信息源对地磅信号干扰，保重称重数据的准确性。" +
          "\n" +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
          "系统对接红外定防作弊系统，防止车辆不完全上磅或是前后两辆车压磅的作弊行为。",
      },
    };
  },
  mounted() {
    console.log(this.$route.query.id);
    this.id = this.$route.query.id;

    getByWebsiteProductId(this.$route.query.id).then((response) => {
      console.log(response);
      this.detailList = response.data.func;
      this.detail = response.data;
    });

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://unpkg.com/element-ui/lib/theme-chalk/index.css"; // 替换为你的CDN链接
    document.head.appendChild(link);
  },
  methods: {
    scrollToElement(elementId) {
      const el = document.getElementById(elementId);
      if (el) {
        window.scrollTo({
          top: el.offsetTop,
          behavior: "smooth",
        });
      }
    },

    handleClick(tab, event) {
      console.log(tab.index + 1);
      switch (tab.index) {
        case "0":
          this.moduleArray = this.moduleArray01;
          break;
        case "1":
          this.moduleArray = this.moduleArray02;

          break;
        case "2":
          this.moduleArray = this.moduleArray03;

          break;
        case "3":
          this.moduleArray = this.moduleArray04;

          break;
        case "4":
          this.moduleArray = this.moduleArray05;

          break;
        case "5":
          this.moduleArray = this.moduleArray06;

          break;
        case "6":
          this.moduleArray = this.moduleArray07;

          break;
        case "7":
          this.moduleArray = this.moduleArray08;

          break;
        case "8":
          this.moduleArray = this.moduleArray09;

          break;
      }
      this.pageNum = 1;
      this.getListByNewsType();
    },
  },
};
</script>

<style scoped>
/* 媒体查询 */
@media (min-width: 768px) {
  /* PC样式 */
  .detail-container {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 15% 0;
  }
  .detail-item {
    display: flex;
    flex-direction: row;
    width: 30%;
    height: 150px;
    margin: 0 1.6% 30px;
    box-shadow: 0 4px 8px rgba(34, 33, 48, 0.5);
    border-radius: 10px;
  }
  .detail-item-container {
    display: flex;
    flex-direction: row;
  }
  .detail-item-div {
    display: flex;
    flex-direction: column;
    margin: auto 0;
  }
  .detail {
    font-size: 15px;
    color: rgb(87, 87, 87);
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    margin-top: 10px;
  }
  .box {
    transition: transform 1s ease-in-out; /* 平滑过渡动画 */
  }
  .thumbnail:hover .box {
    transform: rotateY(360deg); /* 沿Y轴旋转360度 */
  }

  .system_overview_container {
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 0 2.5%;
  }

  .system_overview_container_img {
    width: 90%;
  }

  .detail01_frame_diagram {
    width: 67%;
  }

  .detail01_frame_diagram_item {
    display: flex;
    margin-top: 30px;
  }

  .detail01_frame_diagram_item_img {
    width: 15%;
  }

  .detail01_frame_diagram_item_content_container_left {
    margin-left: 100px;
  }

  .detail01_frame_diagram_item_content_container_right {
    margin-right: 100px;
  }

  .detail02_income_value_container {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 0 auto;
  }

  .detail02_income_value_content_top {
    display: flex;
    flex-direction: column;
    margin-left: 200px;
    justify-content: center;
  }

  .detail03_container {
    width: 67%;
    margin: 30px auto;
    display: flex;
    flex-direction: row;
  }

  .detail03_container_item {
    width: 48%;
    display: flex;
    flex-direction: column;
    margin: 0 2% 0 2%;
  }

  .detail03_container_line_column {
    border: 1px solid #ccc;
  }

  .detail03_container_line_row {
    border: 1px solid #ccc;
    width: 67%;
    margin: 0 auto;
  }

  .detail03_product_value {
    width: 67%;
  }

  .detail03_product_value_bottom {
    width: 60%;
    margin: 50px auto 0;
    display: flex;
    flex-direction: row;
  }

  .detail03_product_value_bottom_img {
    width: 40%;
  }

  .detail03_product_value_bottom_content {
    padding-left: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .detail06_oa {
    display: flex;
    flex-direction: row;
    width: 73%;
    margin: 0 13.5%;
  }

  .detail06_oa_item {
    width: 25%;
    border: 1px solid #a0a0a0;
    border-radius: 20px;
    margin: 0 4.1%;
  }

  .detail06_oa_function {
    width: 70%;
    margin: 0 15%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .detail06_oa_function_item {
    background-color: #f1f1f1;
    border-radius: 10px;
    width: 16%;
    margin: 10px 2%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .detail06_oa_function_item_img {
    width: 50px;
    height: 50px;
  }

  .detail06_oa_function_item_title {
    margin-left: 20px;
    font-size: 16px;
    color: black;
  }

  .detail06_oa_role_left {
    width: 80%;
    margin: 50px 10% 100px;
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
  }

  .detail06_oa_role_content {
    width: 50%;
    margin: auto 0;
  }

  .detail06_oa_role_content_text {
    background-color: #524e4e;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    padding: 2px 10px;
  }

  .detail08_development_process {
    width: 50%;
    margin: 0 25%;
  }

  .detail08_desc {
    padding: 0 400px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-weight: 700;
    font-size: 16px;
  }

  .detail08_service_content {
    width: 60%;
    margin: 50px 20%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .detail08_service_content_item {
    border-radius: 30px;
    width: 25%;
    height: 350px;
    margin: 0 4.1% 50px;
    display: flex;
    flex-direction: column;
    border: 1px solid #edecec;
  }

  .detail08_service_content_item_text {
    font-size: 0.7vw;
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  /* 移动端样式 */

  .detail-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 20px 15% 0;
  }
  .detail-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    margin: 0 1.6% 30px;
    box-shadow: 0 4px 8px rgba(34, 33, 48, 0.5);
    border-radius: 10px;
  }
  .detail-item-container {
    display: flex;
    flex-direction: row;
  }
  .detail-item-container img {
    width: 50px;
    height: 50px;
    margin-left: 10px;
  }
  .detail-item-div {
    display: flex;
    flex-direction: column;
    margin: auto 0 auto 10px;
  }
  .detail {
    font-size: 15px;
    color: rgb(87, 87, 87);
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    margin-top: 10px;
  }

  .system_overview_container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 2.5%;
  }

  .system_overview_container_img {
    width: 80%;
    margin: 0 auto
  }

  .detail01_frame_diagram {
    width: 90%;
  }

  .detail01_frame_diagram_item {
    margin-top: 30px;
  }

  .detail01_frame_diagram_item_img {
    width: 100%;
  }

  .detail01_frame_diagram_item_content_container_left {
    margin-left: 0;
  }

  .detail01_frame_diagram_item_content_container_right {
    margin-right: 0;
  }

  .detail02_income_value_container {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
  }

  .detail02_income_value_content_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .detail03_container {
    width: 90%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
  }

  .detail03_container_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  .detail03_product_value {
    width: 98%;
  }

  .detail03_product_value_bottom {
    width: 80%;
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
  }

  .detail03_product_value_bottom_img {
    width: 100%;
  }

  .detail03_product_value_bottom_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .detail06_oa {
    display: flex;
    flex-direction: column;
    width: 73%;
    margin: 0 13.5%;
  }

  .detail06_oa_item {
    width: 100%;
    border: 1px solid #a0a0a0;
    border-radius: 20px;
    margin: 0 4.1% 10px;
  }

  .detail06_oa_function {
    width: 90%;
    margin: 0 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .detail06_oa_function_item {
    background-color: #f1f1f1;
    border-radius: 10px;
    width: 16%;
    margin: 10px 2%;
    height: 80px;
    justify-content: center;
    align-items: center;
  }

  .detail06_oa_function_item_img {
    width: 50%;
    height: 30px;
    margin: 10px 25%;
  }

  .detail06_oa_function_item_title {
    font-size: 10px;
    color: black;
    text-align: center;
  }

  .detail06_oa_role_left {
    width: 100%;
    justify-content: space-between;
    padding: 0 10%;
    margin-top: 100px;
  }

  .detail06_oa_role_content {
    width: 100%;
    margin: 10px 0;
  }

  .detail06_oa_role_content_text {
    background-color: #524e4e;
    border-radius: 10px;
    color: white;
    font-size: 10px;
    padding: 2px 10px;
  }

  .detail08_development_process {
    width: 100%;
  }

  .detail08_desc {
    padding: 0 40px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-weight: 700;
    font-size: 16px;
  }

  .detail08_service_content {
    width: 60%;
    margin: 50px 20%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .detail08_service_content_item {
    border-radius: 30px;
    width: 100%;
    height: 350px;
    margin: 0 0 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #edecec;
  }

  .detail08_service_content_item_text {
    font-size: 12px;
    margin-right: 10px;
  }

  .media-img{
    display: none;
  }


  
}
</style>
