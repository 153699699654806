<template>
  <Layout header-class="" :show-newsletter="true" :show-cta="false">
    <!-- Start Slider Area -->
    <div
      class="slider-area slider-style-1 bg-transparent height-650 media-img"
      v-show="false"
      :style="{
        'background-image': `url(${require(`@/assets/images/01.png`)})`,
        'background-repeat': 'no-repeat',
        back,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="inner text-center"
              style="
                background: url(../assets/images/01.png);
                background-size: cover;
              "
            >
              <!-- <span class="subtitle" data-aos="fade-up" data-aos-delay="100"
                >靠技术创新，用我们辛勤的劳动，让客户分享更多的产品价值</span
              > -->
              <h1
                data-aos="fade-up"
                data-aos-delay="150"
                style="color: white; font-size: 70px"
              >
                山西卓能科技有限公司 <br />
                <vue-typed-js
                  class="justify-content-center"
                  :loop="true"
                  :type-speed="80"
                  :start-delay="1000"
                  :back-speed="50"
                >
                  <!-- <b class="is-visible theme-gradient typing">耐磨</b> -->
                </vue-typed-js>
              </h1>
              <h4
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-align: left;
                  text-indent: 3ch;
                  color: white;
                  font-size: 24px;
                  margin-top: 50px;
                "
              >
                山西卓能科技有限公司创立于2023年，是一家以万物互联、AI赋能，推动国家工业4.0发展进程为使命，以物联网、人工智能、大数据为核心的科技型企业，我们秉承客户至上、
                共谋发展、和谐共赢的服务理念，凭借先进的技术、优秀的团队、丰富的经验，迅速发展，成立至今公司业务已遍布全国各地，已为数十家企业提供高效、智能的工业软件，
                及时、细致的技术支持和售后服务，不仅为我们赢得了良好的社会信誉，更为企业实现了便捷智能、降本增效的目的。
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <div
      class="slider-area slider-style-1 height-850 bg_image media-img"
      :style="{
        'background-image': `url(${require(`@/assets/images/about.png`)})`,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <!-- <div class="react-image mb--20" data-aos="fade-up">
                <img
                  src="../assets/images/demo/badge-vue-js.png"
                  alt="卓能科技"
                />
              </div> -->
              <!-- <div>
                <h3
                  class="rn-sub-badge"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <span class="theme-gradient"
                    >产品案例</span
                  >
                </h3>
              </div> -->
              <!-- <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="color: white;"
              >
              山西卓能科技有限公司
              </h1>
              <h4
                data-aos="fade-up"
                data-aos-delay="280"
                style="text-align: left; text-indent: 3ch; color: white; font-size: 24px; margin-top: 50px;"
              >
                山西卓能科技有限公司创立于2023年，是一家以万物互联、AI赋能，推动国家工业4.0发展进程为使命，以物联网、人工智能、大数据为核心的科技型企业，我们秉承客户至上、
                共谋发展、和谐共赢的服务理念，凭借先进的技术、优秀的团队、丰富的经验，迅速打开市场，成立至今公司业务已遍布全国各地，已为数十家企业提供高效、智能的工业软件，
                及时、细致的技术支持和售后服务，不仅为我们赢得了良好的社会信誉，更为越来越多的企业实现了便捷智能、降本增效的目的。
              </h4> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container"
      style="margin-top: 100px; display: flex; flex-direction: row"
      data-aos="fade-up"
      data-aos-delay="280"
    >
      <div
        v-for="(page, index) in list"
        :key="index"
        class="leave-shadow"
        @mouseenter="changeStyle('enter-shadow', $event)"
        @mouseleave="changeStyle('leave-shadow', $event)"
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            width: 100%;
          "
        >
          <div
            style="
              text-align: center;
              margin: 20px auto 0;
              width: 100%;
              font-weight: bold;
              font-size: 22px;
              color: black;
            "
          >
            <h4
              class="title theme-gradient"
              data-aos="fade-up"
              data-aos-delay="280"
            >
              {{ page.title }}
            </h4>
            <!-- {{ page.websiteProductName }} -->
          </div>

          <div
            class="detail"
            @mouseenter="changeStyle('enter-detail', $event)"
            @mouseleave="changeStyle('leave-detail', $event)"
          >
            {{ page.content }}
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%">
      <h2 style="text-align: center">服务企业</h2>
      <div class="service_enterprises">
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          蒲县豹子沟煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          蒲县能源有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          河曲旧县露天煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          左云东古城煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          左云韩家洼煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          鹿台山煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          霍尔辛赫煤业有限责任公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          凌志达煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          长治经坊庄子河煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          左权宏远煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          朔州有限公司大峪口选煤厂
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          忻州有限公司安塘集运站
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          山西大平煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          山煤国际能源集团晋中有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          铺龙湾煤业有限公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          山西华晋吉宁煤业有限责任公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          山西华晋明珠煤业有限责任公司
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-delay="280"
          class="service_enterprises_name"
        >
          山西潞安焦化有限责任公司
        </h4>
      </div>
    </div>

    <div style="width: 100%">
      <h2 class="cooperative_enterprise" style="">合作企业</h2>
      <img
        class="cooperation-img"
        style=""
        src="../assets/images/about/cooperation.png"
        alt=""
      />
    </div>

    <div v-show="false">
      <div class="container" style="margin-top: 100px">
        <div class="row row--30 align-items-center">
          <div class="col-lg-7 mt_md--40 mt_sm--40">
            <div class="content">
              <div class="section-title">
                <h2
                  class="title theme-gradient"
                  data-aos="fade-up"
                  data-aos-delay="280"
                  style="text-align: center"
                >
                  我们的使命
                </h2>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <h4
                class="title theme-gradient"
                data-aos="fade-up"
                data-aos-delay="280"
              >
                万物互联 AI赋能 推动工业4.0
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style="margin-top: 100px">
        <div class="row row--30 align-items-center">
          <div class="col-lg-7 mt_md--40 mt_sm--40">
            <div class="content">
              <div class="section-title">
                <h2
                  class="title theme-gradient"
                  data-aos="fade-up"
                  data-aos-delay="280"
                  style="text-align: center"
                >
                  我们的理念
                </h2>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <h4
                class="title theme-gradient"
                data-aos="fade-up"
                data-aos-delay="280"
              >
                追求卓越，创新突破
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style="margin-top: 100px">
        <div class="row row--30 align-items-center">
          <div class="col-lg-7 mt_md--40 mt_sm--40">
            <div class="content">
              <div class="section-title">
                <h2
                  class="title theme-gradient"
                  data-aos="fade-up"
                  data-aos-delay="280"
                  style="text-align: center"
                >
                  我们的团队
                </h2>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <h4
                class="title theme-gradient"
                data-aos="fade-up"
                data-aos-delay="280"
              >
                高效管理，精益求精
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style="margin-top: 100px">
        <div class="row row--30 align-items-center">
          <div class="col-lg-7 mt_md--40 mt_sm--40">
            <div class="content">
              <div class="section-title">
                <h2
                  class="title theme-gradient"
                  data-aos="fade-up"
                  data-aos-delay="280"
                  style="text-align: center"
                >
                  我们对客户
                </h2>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <h4
                class="title theme-gradient"
                data-aos="fade-up"
                data-aos-delay="280"
              >
                客户至上，服务至上 共谋发展，和谐共赢
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Slider Area -->
    <div class="height-650" v-show="false">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <div>
                <h3
                  class="rn-sub-badge"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <span class="theme-gradient"
                    >Our Company's About Details.</span
                  >
                </h3>
              </div>
              <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                We are a Corporate <br />
                Business Agency.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Service Area -->
    <div class="service-area rn-section-gapTop" v-show="false">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="content" data-aos="fade-up">
              <h3 class="title">
                We are creative digital agency working for our company brands.
              </h3>
            </div>
          </div>
          <div class="col-lg-6">
            <p class="mb--10" data-aos="fade-up">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod,
              quis. Ullam accusantium dignissimos repellendus nemo fugiat
              numquam, nisi odio adipisci. Veniam neque itaque expedita officiis
              rem ipsa! Ratione, rem reiciendis?
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Service Area -->

    <!-- Start Brand Area -->
    <div class="rwt-brand-area pb--60 pt--30" v-show="false">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mt--10">
            <Brand :brand-list="brandList" :brand-style="3" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Brand Area -->

    <Separator />

    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gap" v-show="false">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="What we can do for you"
              title="Services provide for you."
              description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceOne
          service-style="service__style--1 bg-color-blackest radius mt--25"
          icon-size="62"
          text-align="center"
        />
      </div>
    </div>
    <!-- End Service Area -->

    <div class="rwt-timeline-area rn-section-gapBottom" v-show="false">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" :timeline-style="3" />
          </div>
        </div>
      </div>
    </div>

    <Separator />

    <AboutFour
      :image="require(`@/assets/images/about/contact-image.jpg`)"
      v-show="false"
    />

    <!-- Start Elements Area -->
    <div class="rwt-counterup-area pb--100" v-show="false">
      <div class="container mt_dec--30">
        <Counter
          :counter-style="5"
          text-align="center"
          :counter-data="counterData"
          column="col-lg-3 col-md-6 col-sm-6 col-12"
        />
      </div>
    </div>
    <!-- End Elements Area -->

    <Separator />

    <!-- Start Elements Area -->
    <div class="rwt-team-area rn-section-gap" v-show="false">
      <div class="container">
        <div class="row mb--20">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Our Corporate Team."
              title="Corporate Team Member."
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
            v-for="(teamMember, index) in teamData"
            :key="index"
          >
            <Team :team-member="teamMember" :team-style="4" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Elements Area -->

    <!-- Start Contact Area  -->
    <div class="rwt-contact-area rn-section-gap">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"
                            subtitle="Contact Form"
                            title="Our Contact Address Here"
                        />
                    </div> -->
        </div>
        <div class="row row--15">
          <div class="col-lg-12">
            <div class="rn-contact-address mt_dec--30">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="rn-address">
                    <div class="icon">
                      <Icon name="headphones" :size="40" />
                    </div>
                    <div class="inner" style="height: 70px">
                      <h4 class="title">联系方式</h4>
                      <p>马经理：18636563050<br />服务电话：400 994 0599</p>
                      <!-- <p><a href="tel:+222 222 222 333">+13856985639</a></p> -->
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12">
                  <div class="rn-address">
                    <div class="icon">
                      <Icon name="mail" :size="40" />
                    </div>
                    <div class="inner" style="height: 70px">
                      <h4 class="title">邮箱地址</h4>
                      <p>
                        <a href="mailto:admin@gmail.com"
                          >master@topowersx.com</a
                        >
                      </p>
                      <!-- <p><a href="mailto:example@gmail.com">master@topowersx.com</a></p> -->
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12">
                  <div class="rn-address">
                    <div class="icon">
                      <Icon name="map-pin" :size="40" />
                    </div>
                    <div class="inner" style="height: 70px">
                      <h4 class="title">联系地址</h4>
                      <p>山西省太原市晋源区义井街国投大厦8层</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt--40 row--15">
          <!-- <div class="col-lg-7">
                        <ContactForm/>
                    </div> -->
          <div class="col-lg-15 mt_md--30 mt_sm--30">
            <GoogleMap />
          </div>
        </div>
      </div>
    </div>
    <!-- End Contact Area  -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";

import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";

export default {
  name: "About",
  metaInfo: {
    //改变当前路由的title
    title: "关于我们",
    //改变当前路由的link
    meta: [
      {
        name: "keyWords",
        content: "卓能科技官网",
      },
      {
        name: "description",
        content: "无人值守",
      },
    ],
  },
  components: {
    Team,
    Brand,
    Counter,
    Timeline,
    ServiceOne,
    AboutFour,
    SectionTitle,
    Separator,
    Layout,
    ContactForm,
    GoogleMap,
    Icon,
    Breadcrumb,
  },
  data() {
    return {
      brandList: [
        {
          image: require(`@/assets/images/brand/brand-01.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-02.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-03.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-04.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-05.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-06.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-07.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-08.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-01.png`),
        },
      ],
      timelineData: [
        {
          id: "1",
          title: "Knowledge",
          description: "Present all available features in Essentials.",
        },
        {
          id: "2",
          title: "Working",
          description: "All Feature available features in Essentials.",
        },
        {
          id: "3",
          title: "Solution",
          description: "Popular Feature available features in Essentials.",
        },
        {
          id: "4",
          title: "Process",
          description: "Latest Feature available features in Essentials.",
        },
      ],
      counterData: [
        {
          number: 199,
          title: "Happy Clients",
        },
        {
          number: 575,
          title: "Employees",
        },
        {
          number: 69,
          title: "Useful Programs",
        },
        {
          number: 500,
          title: "Useful Programs",
        },
      ],
      teamData: [
        {
          image: "team-dark-01",
          name: "Sr Janen Sara",
          designation: "Sr Product Designer",
          location: "CO Miego, AD, USA",
          description:
            "Yes, I am a product designer. I have a passion for product design.",
        },
        {
          image: "team-dark-02",
          name: "Corporate Jane",
          designation: "Manager",
          location: "Bangladesh",
          description:
            "Yes, I am a product designer. I have a passion for product design.",
        },
        {
          image: "team-dark-03",
          name: "Jara Saraif",
          designation: "Software Developer",
          location: "Poland",
          description:
            "Yes, I am a product designer. I have a passion for product design.",
        },
        {
          image: "team-dark-04",
          name: "Afanan Sifa",
          designation: "Accounts Manager",
          location: "Poland",
          description:
            "Yes, I am a product designer. I have a passion for product design.",
        },
      ],
      list: [
        { title: "我们的使命", content: "万物互联 AI赋能 推动工业4.0" },
        { title: "我们的理念", content: "追求卓越，创新突破" },
        { title: "我们的团队", content: "高效管理，精益求精" },
        {
          title: "我们的对客户",
          content: "客户至上，服务至上 共谋发展，和谐共赢",
        },
      ],
    };
  },
  methods: {
    changeStyle(type, e) {
      if (type == "enter") {
        e.currentTarget.className = "enter-active";
      } else if (type == "leave") {
        e.currentTarget.className = "active";
      } else if (type == "enter-detail") {
        e.currentTarget.className = "enter-detail";
      } else if (type == "leave-detail") {
        e.currentTarget.className = "detail";
      } else if (type == "enter-shadow") {
        e.currentTarget.className = "enter-shadow";
      } else if (type == "leave-shadow") {
        e.currentTarget.className = "leave-shadow";
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .media-img{

  }
  .image-slot {
    justify-content: center;
  }
  .center-icon {
    padding-top: 50px;
  }
  .active {
    margin: 10px auto 20px;
    border: 1px solid #9a9a9a;
    border-radius: 10px;
    font-size: 12px;
    color: #9a9a9a;
    padding: 5px 10px;
    width: 30%;
    text-align: center;
  }
  .enter-active {
    margin: 10px auto 20px;
    border-radius: 10px;
    font-size: 12px;
    color: white;
    padding: 5px 10px;
    background-color: #3758ff;
    width: 30%;
    text-align: center;
  }

  .detail {
    text-align: center;
    font-size: 15px;
    color: black;
    margin: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .enter-detail {
    text-align: center;
    font-size: 15px;
    color: black;
    margin: 10px;
  }

  .leave-shadow {
    display: flex;
    flex-direction: row;
    width: 20%;
    margin: 0 2.5% 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .enter-shadow {
    display: flex;
    flex-direction: row;
    width: 20%;
    margin: 0 2.5% 100px;
    box-shadow: 0 4px 8px rgba(61, 51, 240, 0.5);
    border-radius: 10px;
  }
  .cooperation-img {
    margin: 0 20%;
    width: 60%;
  }

  .service_enterprises {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;
    margin: 50px 20%;
    text-align: center;
    border: 1px solid #cec9c7;
    border-radius: 10px;
    padding: 50px 10px;
  }

  .service_enterprises_name {
    width: 33%;
    line-height: 150%;
    color: rgb(71, 71, 71);
    font-size: 21px;
  }

  .cooperative_enterprise {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .media-img{
   display: none; 
  }
  .image-slot {
    justify-content: center;
  }
  .center-icon {
    padding-top: 50px;
  }
  .active {
    margin: 10px auto 20px;
    border: 1px solid #9a9a9a;
    border-radius: 10px;
    font-size: 12px;
    color: #9a9a9a;
    padding: 5px 10px;
    width: 30%;
    text-align: center;
  }
  .enter-active {
    margin: 10px auto 20px;
    border-radius: 10px;
    font-size: 12px;
    color: white;
    padding: 5px 10px;
    background-color: #3758ff;
    width: 30%;
    text-align: center;
  }

  .detail {
    text-align: center;
    font-size: 15px;
    color: black;
    margin: 10px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  .enter-detail {
    text-align: center;
    font-size: 15px;
    color: black;
    margin: 10px;
  }

  .leave-shadow {
    display: flex;
    flex-direction: row;
    width: 20%;
    margin: 0 2.5% 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .enter-shadow {
    display: flex;
    flex-direction: row;
    width: 20%;
    margin: 0 2.5% 100px;
    box-shadow: 0 4px 8px rgba(61, 51, 240, 0.5);
    border-radius: 10px;
  }
  .cooperation-img {
    margin: 0 5%;
    width: 90%;
  }

  .service_enterprises {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 10%;
    text-align: center;
    border: 1px solid #cec9c7;
    border-radius: 10px;
  }

  .service_enterprises_name {
    width: 100%;
    line-height: 150%;
    color: rgb(71, 71, 71);
    font-size: 16px;
    margin-top: 6px;
  }

  .cooperative_enterprise {
    text-align: center;
    margin-top: 10px;
  }
}
</style>
