<template>
  <Layout :footer-style="2">
    <!-- Start Slider Area -->
    <div
      class="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70"
      v-show="false"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <div class="react-image mb--20" data-aos="fade-up">
                <img
                  src="../assets/images/demo/badge-vue-js.png"
                  alt="卓能科技"
                />
              </div>
              <h1
                class="title display-two"
                data-aos="fade-up"
                data-aos-delay="100"
                style=""
              >
                <!-- class="title display-two" -->
                <!-- class="theme-gradient" -->

                工业互联智造未来<br />
                <span class="theme-gradient" style="">智能物流</span> &
                <span class="theme-gradient" style="">无人值守</span>
                <!-- <br/>
                                  Vue.js Template. -->
              </h1>
              <p
                class="description"
                data-aos="fade-up"
                data-aos-delay="150"
                style=""
              >
                管理在线化、运营数字化、人机智能化<br />
                企业智能管理解决方案专业提供商
              </p>
              <div
                class="button-group"
                data-aos="fade-up"
                data-aos-delay="200"
                @click="scrollToElement('demo')"
                style="cursor: pointer"
              >
                <!-- <a class="btn-default btn-large round btn-icon" href="#demo" -->
                <a class="btn-default btn-large round btn-icon"
                  >查看更多
                  <Icon name="arrow-down" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <div
      class="slider-area slider-style-1 height-850 bg_image"
      :style="{
        'background-image': `url(${require(`@/assets/images/image07.png`)})`,
      }"
      v-show="true"
    >
      <div class="" style="width: 100%">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <!-- <div class="react-image mb--20" data-aos="fade-up">
                  <img
                    src="../assets/images/demo/badge-vue-js.png"
                    alt="卓能科技"
                  />
                </div> -->

              <div
                style="
                  color: black;
                  background-color: aliceblue;
                  opacity: 0.8;
                  width: 100%;
                  padding: 10px 0 0;
                "
              >
                <h1
                  class="title display-two"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  style="color: black"
                >
                  <!-- class="title display-two" -->
                  <!-- class="theme-gradient" -->

                  工业互联智造未来<br />
                  <span class="theme-gradient" style="color: black"
                    >智能物流</span
                  >
                  &
                  <span class="theme-gradient" style="color: black"
                    >无人值守</span
                  >
                  <!-- <br/>
                                  Vue.js Template. -->
                </h1>

                <p
                  data-aos="fade-up"
                  data-aos-delay="150"
                  style="
                    color: black;
                    width: 30%;
                    text-align: center;
                    margin: 0 auto 50px;
                    font-size: 20px;
                    font-weight: bold;
                    padding-bottom: 10px;
                  "
                >
                  <!-- class="description" -->
                  管理在线化、运营数字化、人机智能化<br />
                  企业智能管理解决方案专业提供商
                </p>
              </div>

              <div
                class="button-group"
                style="cursor: pointer"
                data-aos="fade-up"
                data-aos-delay="200"
                @click="scrollToElement('demo')"
              >
                <!-- <a class="btn-default btn-large round btn-icon" href="#demo" -->
                <a class="btn-default btn-large round btn-icon"
                  >查看更多
                  <Icon name="arrow-down" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator />

    <!-- Start Our Demo -->
    <div class="rn-demo-area rn-section-gap" id="demos" v-show="false">
      <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              title="软件产品"
              data-aos="fade-up"
            />
            <!--subtitle="All Demo Here" description="We create a business and consulting Vue.js template with 50+ elements features. <br /> 19+ demo pages, faster loading and well documentated code." -->
          </div>
        </div>
        <div class="row row--15">
          <div
            class="col-lg-4 col-md-6 col-12 mt--40"
            v-for="(page, index) in softwareProductList"
            :key="index"
            data-aos="fade-up"
            :data-aos-delay="100 + index"
          >
            <div class="single-demo">
              <!-- <router-link :to="page.url"> -->
              <div class="thumbnail">
                <!-- <img class="image-dark" :src="page.image" alt="Corporate images"/> -->
                <!-- <img class="image-light" :src="page.imageLight" alt="无图片" style="height: 500px;"/> -->
                <el-image
                  class="image-light"
                  :src="page.picUrlsObj != null ? page.picUrlsObj[0].url : ''"
                >
                  <div
                    slot="error"
                    class="image-slot"
                    style="
                      height: 100%;
                      text-align: center;
                      align-items: center;
                      position: relative;
                      display: flex;
                    "
                  >
                    暂无图片
                  </div>
                </el-image>
                <!-- <span v-if="page.badge" class="label-badge">{{ page.badge }}</span> -->
              </div>
              <!-- </router-link> -->
              <h4 class="title">
                <!-- <router-link :to="page.url">{{ page.title }}</router-link> -->
                {{ page.productName }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Our Demo -->

    <Separator />

    <!-- Start Inner Pages -->
    <div class="rn-inner-pages rn-section-gap" v-show="false">
      <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              title="硬件产品"
              data-aos="fade-up"
            />
            <!-- subtitle="All Others Pages" description="Have a nice inner pages include in doob template." -->
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 mt--40"
            v-for="(page, index) in hardwareProductList"
            :key="index"
            data-aos="fade-up"
            :data-aos-delay="100 + index"
          >
            <div class="single-demo">
              <!-- <router-link :to="page.url"> -->
              <div class="thumbnail">
                <!-- <img class="image-dark" :src="page.image" alt="Corporate images"/> -->
                <!-- <img class="image-light" :src="page.imageLight" alt="" style="height: 500px;"/> -->
                <el-image
                  class="image-light"
                  :src="page.picUrlsObj != null ? page.picUrlsObj[0].url : ''"
                >
                  <div
                    slot="error"
                    class="image-slot"
                    style="
                      height: 100%;
                      text-align: center;
                      align-items: center;
                      position: relative;
                      display: flex;
                    "
                  >
                    暂无图片
                  </div>
                </el-image>
              </div>
              <!-- </router-link> -->
              <h4 class="title">
                <!-- <router-link :to="page.url">{{ page.title }}</router-link> -->
                {{ page.productName }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Inner Pages -->

    <!-- Start Inner Pages -->
    <div id="demo" data-aos-delay="280">
      <div>
        <div style="padding: 100px 0 50px">
          <div class="col-lg-12">
            <!-- <SectionTitle
              subtitle="核心"
                text-align="center"
                title="核心产品介绍"
                data-aos="fade-up"
              /> -->
            <h2 style="text-align: center">核心产品介绍</h2>
          </div>
        </div>

        <div class="core-container">
          <div
            v-for="(page, index) in productAllList"
            :key="index"
            class="leave-shadow"
            @mouseenter="changeStyle('enter-shadow', $event)"
            @mouseleave="changeStyle('leave-shadow', $event)"
            @click="getByWebsiteProductIdDetail(page.websiteProductId)"

          >
            <div class="core-container-item">
              <el-image
                class="core-item-img"
                :src="page.icon != null ? page.icon : ''"
              >
                <div
                  slot="error"
                  class="image-slot"
                  style="
                    height: 100%;
                    text-align: center;
                    align-items: center;
                    position: relative;
                    display: flex;
                  "
                >
                  暂无图片
                </div>
              </el-image>
              <div class="core-item-title">
                <h6
                  data-aos="fade-up"
                  data-aos-delay="280"
                  style="font-weight: bold"
                >
                  {{ page.websiteProductName }}
                </h6>
                <!-- {{ page.websiteProductName }} -->
              </div>

              <div
                class="detail"
                @mouseenter="changeStyle('enter-detail', $event)"
                @mouseleave="changeStyle('leave-detail', $event)"
              >
                {{ page.overview }}
              </div>

              <div
                class="active"
                @mouseenter="changeStyle('enter', $event)"
                @mouseleave="changeStyle('leave', $event)"
                @click="getByWebsiteProductIdDetail(page.websiteProductId)"
              >
                <span> 查看详情 </span>
                <!-- <span
                      class="title theme-gradient"
                      data-aos="fade-up"
                      data-aos-delay="280"
                    >
                    查看详情
              </span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Inner Pages -->

    <div class="container" style="margin-bottom: 100px; cursor: pointer">
      <div class="inner text-center">
        <div
          class="button-group"
          data-aos="fade-up"
          data-aos-delay="200"
          @click="more"
        >
          <!-- <a class="btn-default btn-large round btn-icon" href="#demo" -->
          <a class="btn-default btn-large round btn-icon"
            >{{ moreTag ? "点击查看更多产品" : "收起" }}
            <Icon :name="arrowType" />
          </a>
        </div>
      </div>
    </div>

    <div>
      <h3 style="text-align: center">新闻中心</h3>
      <div class="news_item_container">
        <!-- <div style="width: 30%; display: flex; flex-direction: column;" >
          <img
            style="border-radius: 10px"
            :src="newsList1[0].images[0].image"
            alt="卓能科技"
            width="97%"
          />
          <div style="width: 97%">
            <h5 style="margin-top: 20px; font-size: 0.9vw;">{{ newsList1[0].newsName }}</h5>
            <div style="font-size: 13px;text-indent: 3ch; ">{{ newsList1[0].overview }}</div>
          </div>
        </div> -->
        <div class="news_item_container_type" >
          <h4 style="text-align: center; margin: 10px">行业资讯</h4>
          <div
            v-for="(item, index) in newsList1"
            :key="index"
            style="display: flex; flex-direction: column; margin-top: 10px"
            @click="toDetail(item)"

          >
            <span
              class="news"
              @mouseenter="changeStyle('enter-news', $event)"
              @mouseleave="changeStyle('leave-news', $event)"
            >
              {{ index + 1 }}、{{ item.newsName }}
            </span>
            <div style="display: flex; flex-direction: row">
              <img
                width="100"
                height="600"
                style="border-radius: 10px"
                :src="item.images[0].image"
                alt=""
              />
              <div class="new_overview">{{ item.overview }}</div>
            </div>

            <div
              style="
                background-color: #ccc;
                width: 50%;
                height: 2px;
                margin: 10px auto 0;
              "
            ></div>
          </div>
        </div>

        <div
        class="news_item_container_type"
        >
          <h4 style="text-align: center; margin: 10px">卓能动态</h4>
          <div
            v-for="(item, index) in newsList2"
            :key="index"
            style="display: flex; flex-direction: column; margin-top: 10px"
            @click="toDetail(item)"

          >
            <span
              class="news"
              @mouseenter="changeStyle('enter-news', $event)"
              @mouseleave="changeStyle('leave-news', $event)"
            >
              {{ index + 1 }}、{{ item.newsName }}
            </span>
            <div style="display: flex; flex-direction: row">
              <img
                width="100"
                height="600"
                style="border-radius: 10px"
                :src="item.images[0].image"
                alt=""
              />
              <div class="new_overview">{{ item.overview }}</div>
            </div>

            <div
              style="
                background-color: #ccc;
                width: 50%;
                height: 2px;
                margin: 10px auto 0;
              "
            ></div>
          </div>
        </div>

        <div
        class="news_item_container_type"
        >
          <h4 style="text-align: center; margin: 10px">政策法规</h4>

          <div
            v-for="(item, index) in newsList3"
            :key="index"
            style="display: flex; flex-direction: column; margin-top: 10px"
            @click="toDetail(item)"

          >
            <span
              class="news"
              @mouseenter="changeStyle('enter-news', $event)"
              @mouseleave="changeStyle('leave-news', $event)"
            >
              {{ index + 1 }}、{{ item.newsName }}
            </span>
            <div style="display: flex; flex-direction: row">
              <img
                width="100"
                height="600"
                style="border-radius: 10px"
                :src="item.images[0].image"
                alt=""
              />
              <div class="new_overview">{{ item.overview }}</div>
            </div>
            <div
              style="
                background-color: #ccc;
                width: 50%;
                height: 2px;
                margin: 10px auto 0;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="margin: 50px auto; cursor: pointer">
      <div class="inner text-center">
        <div
          class="button-group"
          data-aos="fade-up"
          data-aos-delay="200"
          @click="toNews"
        >
          <!-- <a class="btn-default btn-large round btn-icon" href="#demo" -->
          <a class="btn-default btn-large round btn-icon"
            >点击查看更多新闻资讯
            <!-- <Icon :name="arrowType" /> -->
          </a>
        </div>
      </div>
    </div>

    <div
      class="rn-demo-area rn-section-gap"
      id="demo"
      style="padding-top: 100px"
    >
      <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
        <div class="row">
          <div class="col-lg-12">
            <!-- <SectionTitle text-align="center" subtitle="项目案例" /> -->
            <h3 style="text-align: center">项目案例</h3>
            <!--subtitle="All Demo Here" description="We create a business and consulting Vue.js template with 50+ elements features. <br /> 19+ demo pages, faster loading and well documentated code." -->
          </div>
        </div>
        <div class="row row--15">
          <div
            class="col-lg-4 col-md-6 col-12 mt--40"
            v-for="(page, index) in gallery"
            :key="index"
            data-aos="fade-up"
            :data-aos-delay="100 + index"
            @click="toGalleryDetail(page.caseId)"
          >
            <div style="justify-content: center; align-items: center">
              <!-- <router-link :to="page.url"> -->
              <div style="display: flex">
                <img
                  style="
                    width: 400px;
                    height: 250px;
                    border-radius: 10px;
                    margin: 0 auto;
                  "
                  :src="page.icon"
                  alt="Corporate images"
                />
                <!-- <img class="image-light" :src="page.imageLight" alt="无图片" style="height: 500px;"/> -->

                <!-- <span v-if="page.badge" class="label-badge">{{ page.badge }}</span> -->
              </div>
              <!-- </router-link> -->
              <h5 style="margin-top: 20px; text-align: left; margin-left: 12%">
                <!-- <router-link :to="page.url">{{ page.title }}</router-link> -->
                {{ page.caseName }}
                <Icon
                  style="float: right; margin-right: 13%"
                  name="arrow-right"
                />
              </h5>
              <div
                style="
                  font-size: 14px;
                  margin: 0 15%;
                  text-indent: 3ch;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                <!-- {{ page.caseContent[0].content }} -->
                {{ page.caseTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Icon from "../components/icon/Icon";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import { productList, productListAll } from "@/api/index";
import { listByNewsType } from "@/api/index";
import { caseListAll } from "@/api/index";

export default {
  name: "Demo",
  metaInfo: {
    //改变当前路由的title
    title: "卓能科技",
    //改变当前路由的link
    meta: [
      {
        name: "keyWords",
        content: "卓能科技官网,卓能科技,卓能,山西卓能,山西卓能科技,topowersx",
      },
      {
        name: "description",
        content: "无人值守",
      },
    ],
  },
  components: { Icon, Layout, Separator, SectionTitle },
  data() {
    return {
      demoPages: [
        {
          url: "/business-consulting",
          image: require("@/assets/images/demo/business-consulting.png"),
          imageLight: require("@/assets/images/demo/business-consulting-light.png"),
          title: "无人值守管理系统",
          badge: "",
        },
        {
          url: "/business-consulting-2",
          image: require("@/assets/images/demo/business-consulting-2.png"),
          imageLight: require("@/assets/images/demo/business-consulting-2-light.png"),
          title: "自主装车系统",
          badge: "",
        },
        {
          url: "/corporate",
          image: require("@/assets/images/demo/corporate.png"),
          imageLight: require("@/assets/images/demo/corporate-light.png"),
          title: "仓储管理系统",
          badge: "",
        },
      ],
      innerPages: [
        {
          id: "1",
          url: "/blog-grid",
          image: require("@/assets/images/demo/blog-grid.png"),
          imageLight: require("@/assets/images/demo/blog-grid-light.png"),
          title: "网络控制器",
        },
        {
          id: "2",
          url: "/blog-grid-sidebar",
          image: require("@/assets/images/demo/blog-grid-sidebar.png"),
          imageLight: require("@/assets/images/demo/blog-grid-sidebar-light.png"),
          title: "车牌识别",
        },
        {
          id: "3",
          url: "/blog-list-view",
          image: require("@/assets/images/demo/blog-list-view.png"),
          imageLight: require("@/assets/images/demo/blog-list-view-light.png"),
          title: "Led屏幕",
        },
        {
          id: "4",
          url: "/blog-list-sidebar",
          image: require("@/assets/images/demo/blog-list-sidebar.png"),
          imageLight: require("@/assets/images/demo/blog-list-sidebar-light.png"),
          title: "热敏打印机",
        },
        {
          id: "5",
          url: "/blog-details/3",
          image: require("@/assets/images/demo/blog-details.png"),
          imageLight: require("@/assets/images/demo/blog-details-light.png"),
          title: "针式打印机",
        },
        {
          id: "6",
          url: "/portfolio",
          image: require("@/assets/images/demo/portfolio.png"),
          imageLight: require("@/assets/images/demo/portfolio-light.png"),
          title: "室外音柱",
        },
      ],
      listType0: {
        type: 0,
      },
      softwareProductList: [],
      listType1: {
        type: 1,
      },
      hardwareProductList: [],

      productAllList: [],
      moreTag: true,
      arrowType: "arrow-down",

      newsList1: [
        {
          newsId: 0,
          images: [
            {
              image: "",
            },
          ],
          overview: "",
          newsType: "",
          newsName: "",
          newsContent: "",
        },
      ],
      newsList2: [
        {
          newsId: 0,
          images: [
            {
              image: "",
            },
          ],
          overview: "",
          newsType: "",
          newsName: "",
          newsContent: "",
        },
      ],
      newsList3: [
        {
          newsId: 0,
          images: [
            {
              image: "",
            },
          ],
          overview: "",
          newsType: "",
          newsName: "",
          newsContent: "",
        },
      ],

      gallery: [],
    };
  },
  methods: {
    toNews() {
      this.$router.push({ path: "/news" });
    },
    toGalleryDetail(index) {
      this.$router.push({ path: "/galleryDetail", query: { index: index } });
    },

    toDetail(item) {
      this.$router.push({ path: "/newsDetail", query: { item: item } });
    },
    // index(){
    //     productList(this.listType0).then(response => {
    //         console.log(response);
    //         this.softwareProductList = response.rows
    //     })
    //     productList(this.listType1).then(response => {
    //         console.log(response);
    //         this.hardwareProductList = response.rows
    //     })
    // },

    getProductListAll() {
      productListAll().then((response) => {
        console.log(response);
        for (let i = 0; i < 8; i++) {
          // this.productAllList = response.data;
          this.productAllList.push(response.data[i]);
        }
      });
    },
    changeStyle(type, e) {
      if (type == "enter") {
        e.currentTarget.className = "enter-active";
      } else if (type == "leave") {
        e.currentTarget.className = "active";
      } else if (type == "enter-detail") {
        e.currentTarget.className = "enter-detail";
      } else if (type == "leave-detail") {
        e.currentTarget.className = "detail";
      } else if (type == "enter-shadow") {
        e.currentTarget.className = "enter-shadow";
      } else if (type == "leave-shadow") {
        e.currentTarget.className = "leave-shadow";
      } else if (type == "enter-news") {
        e.currentTarget.className = "active-news";
      } else if (type == "leave-news") {
        e.currentTarget.className = "leave-news";
      }
    },
    getByWebsiteProductIdDetail(id) {
      console.log(id);

      this.$router.push({ path: "/detail", query: { id: id } });
    },
    scrollToElement(elementId) {
      const el = document.getElementById(elementId);
      if (el) {
        window.scrollTo({
          top: el.offsetTop,
          behavior: "smooth",
        });
      }
    },
    more() {
      if (this.moreTag) {
        productListAll().then((response) => {
          console.log(response);
          this.productAllList = response.data;
          this.moreTag = false;
          this.arrowType = "arrow-up";
        });
      } else {
        productListAll().then((response) => {
          this.productAllList = [];

          for (let i = 0; i < 8; i++) {
            this.productAllList.push(response.data[i]);
          }
          this.moreTag = true;
          this.arrowType = "arrow-down";
        });
      }
    },
  },
  created() {
    // this.index();
    this.getProductListAll();
    listByNewsType(1, 1, 3).then((response) => {
      if (response.code == 200) {
        this.newsList1 = response.rows;
      }
    });
    listByNewsType(2, 1, 3).then((response) => {
      if (response.code == 200) {
        this.newsList2 = response.rows;
      }
    });
    listByNewsType(3, 1, 3).then((response) => {
      if (response.code == 200) {
        this.newsList3 = response.rows;
      }
    });

    caseListAll().then((response) => {
      console.log(response);
      this.gallery = response.data.slice(0, 3);
    });
  },
};
</script>

<style scoped>
.image-slot {
  justify-content: center;
}
.center-icon {
  padding-top: 50px;
}

/* 媒体查询 */
@media (min-width: 768px) {
  /* PC样式 */
  .core-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 10% 0;
  }
  .leave-shadow {
    display: flex;
    flex-direction: row;
    width: 20%;
    margin: 0 2.5% 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .enter-shadow {
    display: flex;
    flex-direction: row;
    width: 20%;
    margin: 0 2.5% 100px;
    box-shadow: 0 4px 8px rgba(61, 51, 240, 0.5);
    border-radius: 10px;
  }

  .core-container-item {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    perspective: 800px; /* 设置透视距离，增加3D效果 */
  }
  .core-item-img {
    width: 50px;
    height: 50px;
    margin: 10px auto 0;
    transition: transform 1s ease-in-out; /* 平滑过渡动画 */
  }
  .core-container-item:hover .core-item-img {
    transform: rotateY(360deg); /* 沿Y轴旋转360度 */
  }
  .core-item-title {
    text-align: center;
    margin: 20px auto 0;
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    color: black;
  }
  .detail {
    text-align: center;
    font-size: 13px;
    color: rgb(83, 83, 83);
    margin: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .enter-detail {
    text-align: center;
    font-size: 13px;
    color: black;
    margin: 10px;
  }
  .active {
    margin: 10px auto 20px;
    border: 1px solid #9a9a9a;
    border-radius: 10px;
    font-size: 10px;
    color: #9a9a9a;
    padding: 5px 10px;
    width: 30%;
    text-align: center;
  }
  .enter-active {
    margin: 10px auto 20px;
    border-radius: 10px;
    font-size: 10px;
    color: white;
    padding: 5px 10px;
    background-color: #3758ff;
    width: 30%;
    text-align: center;
  }
  .news {
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .active-news {
    text-align: left;
    cursor: pointer;
    color: #3758ff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .leave-news {
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .new_overview {
    font-size: 13px;
    margin: 0 10px;
    text-indent: 3ch;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .news_item_container {
    width: 80%;
    margin: 0 10%;
    display: flex;
  }

  .news_item_container_type {
    width: 33%;
    padding: 0 10px;
  }
}

@media (max-width: 767px) {
  /* 移动端样式 */
  .core-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 5% 0;
  }
  .leave-shadow {
    display: flex;
    flex-direction: row;
    width: 40%;
    margin: 0 2.5% 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .enter-shadow {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 0 2.5% 100px;
    box-shadow: 0 4px 8px rgba(61, 51, 240, 0.5);
    border-radius: 10px;
  }

  .core-container-item {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }
  .core-item-img {
    width: 60px;
    height: 50px;
    margin: 10px auto 0;
    display: none;
  }
  .core-item-title {
    text-align: center;
    margin: 20px auto 0;
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    color: black;
  }
  
  .detail {
    text-align: center;
    font-size: 13px;
    color: rgb(83, 83, 83);
    margin: 10px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */


  }
  
  .enter-detail {
    text-align: center;
    font-size: 13px;
    color: black;
    margin: 10px;
  }
  .active {
    margin: 100px 20% 10px;
    border: 1px solid #9a9a9a;
    border-radius: 10px;
    font-size: 10px;
    color: #9a9a9a;
    padding: 5px 10px;
    width: 60%;
    text-align: center;
    position: absolute;
    bottom: 0;
    display: none;

  }
  /* .enter-active {
    margin: 10px auto 20px;
    border-radius: 10px;
    font-size: 10px;
    color: white;
    padding: 5px 10px;
    background-color: #3758ff;
    width: 30%;
    text-align: center;
  } */

  .news_item_container {
    width: 90%;
    margin: 0 5%;
  }

  .news_item_container_type {
    width: 100%;
    padding: 0 10px;
  }

  .new_overview {
    font-size: 13px;
    margin: 0 10px;
    text-indent: 3ch;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
}
</style>
