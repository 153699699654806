import request from '@/utils/request'

// 产品查询列表
export function productList(query) {
  return request({
    url: '/base/product/list',
    method: 'get',
    params:query
  })
}

//官网提交需求
export function submitRequirement(data) {
  return request({
    url: '/website/info/submitRequirement',
    method: 'post',
    data:data
  })
}

//查询全部官网产品
//  /website/product/listAll
export function productListAll(query) {
  return request({
    url: '/website/product/listAll',
    method: 'get',
    params:query
  })
}

// /website/product/detail/getByWebsiteProductId 查询官网详情
export function getByWebsiteProductId(id) {
  return request({
    url: '/website/product/detail/getByWebsiteProductId?websiteProductId=' + id,
    method: 'get',
    // params:query
  })
}


//查询全部项目案例
//  /website/case/listAll
export function caseListAll(query) {
  return request({
    url: '/website/case/listAll',
    method: 'get',
    params:query
  })
}

export function getCaseById(query) {
  return request({
    url: '/website/case/getById',
    method: 'get',
    params:query
  })
}

//分类查询新闻列表-分页
// /website/news/listByNewsType
export function listByNewsType(newsType, pageNum, pageSize) {
  return request({
    url: '/website/news/listByNewsType?newsType=' + newsType + '&pageNum=' + pageNum + '&pageSize=' + pageSize,
    method: 'get',
    // params:query
  })
}


