var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'header-transparent-with-topbar': _vm.data.headerTransparency }},[(_vm.data.showTopHeader && _vm.data.topbarStyle === 2)?_c('div',{staticClass:"header-top-bar"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-12 col-12"},[_c('div',{staticClass:"header-left"},[_c('p',[_c('a',{attrs:{"href":"#"}},[_vm._v("Get the most advanced template "),_c('Icon',{attrs:{"name":"chevron-right"}})],1)])])]),_c('div',{staticClass:"col-lg-8 col-md-12 col-12"},[_c('div',{staticClass:"header-right"},[_c('div',{staticClass:"address-content"},[_c('p',[_c('Icon',{attrs:{"name":"map-pin"}}),_c('span',[_vm._v("Alabama, USA")])],1),_c('p',[_c('Icon',{attrs:{"name":"phone"}}),_vm._m(0)],1)]),_c('div',{staticClass:"social-icon-wrapper"},[_c('ul',{staticClass:"social-icon social-default icon-naked"},[_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/"}},[_c('Icon',{attrs:{"name":"facebook"}})],1)]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.twitter.com"}},[_c('Icon',{attrs:{"name":"twitter"}})],1)]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com"}},[_c('Icon',{attrs:{"name":"instagram"}})],1)]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.linkedin.com"}},[_c('Icon',{attrs:{"name":"linkedin"}})],1)])])])])])])])]):_vm._e(),_c('header',{class:[
      `rn-header header-default header-not-transparent header-sticky ${_vm.data.headerClass}`,
    ]},[_c('div',{staticClass:"container position-relative"},[_c('div',{staticClass:"row align-items-center row--0"},[[_c('div',{staticClass:"col-lg-3 col-md-6 col-4"},[_c('Logo')],1),_c('div',{staticClass:"col-lg-9 col-md-6 col-8 position-static"},[_c('div',{staticClass:"header-right"},[_c('nav',{staticClass:"mainmenu-nav d-none d-lg-block"},[_c('Nav')],1),_c('div',{staticClass:"mobile-menu-bar ml--5 d-block d-lg-none",staticStyle:{"margin-right":"10px"}},[_c('div',{staticClass:"hamberger"},[_c('button',{staticClass:"hamberger-button",on:{"click":function($event){$event.preventDefault();return _vm.AppFunctions.toggleClass('.popup-mobile-menu', 'active')}}},[_c('Icon',{attrs:{"name":"menu","size":"21"}})],1)])]),_vm._m(1),_vm._m(2)])])]],2)])]),_c('MobileMenu'),_vm._m(3)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a',{attrs:{"href":"#"}},[_vm._v("+06 58 49 99 56")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"href-btn"},[_c('a',{staticStyle:{"color":"#7f7f7f","font-weight":"600","font-size":"12px","border":"1px solid #7f7f7f","border-radius":"10px","padding":"10px 26px"},attrs:{"href":"https://company.topowersx.com/","target":"_blank"}},[_vm._v(" 客户端登录 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"href-btn"},[_c('a',{staticStyle:{"color":"#7f7f7f","font-weight":"600","font-size":"12px","border":"1px solid #7f7f7f","border-radius":"10px","padding":"10px 26px"},attrs:{"href":"https://admin.topowersx.com/","target":"_blank"}},[_vm._v(" 服务端登录 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"rn-gradient-circle"}),_c('div',{staticClass:"rn-gradient-circle theme-pink"})])
}]

export { render, staticRenderFns }