<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14554259.179133086!2d-105.54385245388013!3d37.49334218664659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sbd!4v1630777307491!5m2!1sen!2sbd" width="600" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
        <div id="Map" style="height: 100%;">

        </div>
    </div>
    <!-- End Google MAp -->
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
    // name: 'GoogleMap'
    name: 'IndexMap',
    data() {
        return {
            map: null, //地图实例
        }
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            AMapLoader.load({
                key: "465b43ec984ae95fddecbcdc8b9a365a", // 申请好的Web端开发者Key，首次调用 load 时必填
                //2.0版本太卡了 ，所以使用的1.4.0版本  其插件也有不同  如：ToolBar
                version: "1.4.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                resizeEnable: true,
                plugins: [
                    "AMap.ToolBar", //工具条
                    "AMap.Scale", // 比例尺
                    "AMap.Geolocation", //定位
                ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
                console.log(AMap);
                this.map = new AMap.Map("Map", {
                    viewMode: "3D",
                    zoom: 14,
                    center: [112.49767, 37.817446]
                })

                var marker = new AMap.Marker({
                    position: [112.49767, 37.817446],   // 经纬度
                    anchor: 'center',
                    offset: new AMap.Pixel(0, 0),
                    title: '国投大厦',
                    //创建一个自定义图标实例
                    icon: new AMap.Icon({
                        size: new AMap.Size(28, 30),    // 图标尺寸
                        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',  // 自定义图像的url
                        imageSize: new AMap.Size(27, 30)   // 根据所设置的大小拉伸或压缩图片
                    }),
                })

                // markers.push(marker)
                this.map.add(marker);
                this.showInfoWindow(marker)
            }).catch(e => {
                console.log(e);
            })
        },
        showInfoWindow(marker) {
            let infoWindow = new AMap.InfoWindow({
                isCustom: true, //是否自定义信息窗体
                content: `<div style="background-color: white;padding: 0 5px; border-radius: 5px;border: 1px solid #cccccc;"> 地址：国投大厦</div>`,
                closeWhenClickMap: true,
                zIndex: 999,
                offset: new AMap.Pixel(16, -35)
            });
            infoWindow.open(this.map, marker.getPosition());
        },
    }
}
</script>