<template>
    <!-- <form class="contact-form-1"  @submit="sendForm" :v-model="form"> -->
    <form class="contact-form-1"  onsubmit="return false" :v-model="form">
        <div class="form-group">
            <input
                type="text"
                name="customerName"
                placeholder="客户名称（公司名称)"
                required
                v-model="form.customerName"
            />
        </div>

        <div class="form-group">
            <input
                type="text"
                name="contactPerson"
                placeholder="联系人"
                required
                v-model="form.contactPerson"
            />
        </div>

        <div class="form-group">
            <input
                type="tel"
                name="contactPhone"
                placeholder="联系电话"
                required
                v-model="form.contactPhone"
            />
        </div>


        <div class="form-group">
            <input
                type="text"
                name="address"
                placeholder="客户地址"
                v-model="form.address"
            />
        </div>

        <div class="form-group">
                <textarea
                    name="content"
                    placeholder="客户需求"
                    required
                    v-model="form.content"
                    >
                </textarea>
        </div>

        <div class="form-group">
            <button class="btn-default btn-large" @click="sendForm">提交</button>
        </div>

        <div class="form-group" v-html="showResult ? result : null"/>
    </form>
</template>

<script>

    import {submitRequirement} from "../../../api/index"

    export default {
        name: 'ContactForm',
        data() {
            return {
                showResult: false,
                result: `<p class="success-message">您的信息已提交成功</p>`,
                form : {
                    customerName: "",
                    contactPerson: "",
                    contactPhone: "",
                    address: "",
                    content: ""
                }
            }
        },
        methods: {
            sendForm() {
                console.log(this.form);
                // alert(200)
                // this.showResult = true;                        


                submitRequirement(this.form).then(response => {
                    if(response.code == 200){
                        this.showResult = true;
                        this.form = {
                            customerName: "",
                            contactPerson: "",
                            contactPhone: "",
                            address: "",
                            content: ""
                        }                        
                    }
                })
            }
        }
    }
</script>