import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import 'bootstrap'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueTypedJs from 'vue-typed-js'
import ElementUI from 'element-ui';
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)
Vue.use(ElementUI);

//配置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: 'ad2d3f6ef7313e6ffdcf31f8b9b48625' //*  安全密钥
}

new Vue({
  router,
  created() {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App),
  mounted(){
    // document.dispatchEvent(new Event('render-event'))
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount('#app')
