<template>
    <div class="logo">
      <router-link :to="url">
        <img class="logo-light" :src="lightLogo" alt="Corporate Logo" />
        <img
          :src="darkLogo"
          alt="Corporate Logo"
          style="width: 60px; height: 60px"
        />
        <div
          style="
            display: flex;
            flex-direction: column;
            line-height: 30px;
            margin: 10px 0 0 10px;
          "
        >
          <span class="title-top"> 山 西 卓 能 科 技 有 限 公 司 </span>
          <span class="title-bottom"> TOPOWER  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 物联网 | 人工智能 | 大数据 </span>
        </div>
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: "Logo",
    props: {
      url: {
        type: String,
        default: "/",
      },
      lightLogo: {
        type: String,
        default: function () {
          // return require(`@/assets/images/logo/logo.png`)
          return require(`@/assets/images/demo/badge-vue-js.png`);
        },
      },
      darkLogo: {
        type: String,
        default: function () {
          // return require(`@/assets/images/logo/logo-dark.png`)
          return require(`@/assets/images/demo/badge-vue-js.png`);
        },
      },
    },
  };
  </script>
  
  <style scoped>
  /* 媒体查询 */
  @media (min-width: 768px) {
    /* PC样式 */
  
    .title-top {
      color: black;
      font-size: 20px;
      font-weight: bold;
    }
  
    .title-bottom {
      color: black;
    }
  }
  
  @media (max-width: 767px) {
    /* 移动端样式 */
    .title-top {
      color: black;
      font-size: 15px;
      font-weight: bold;
      width: 200px;
    }
  
    .title-bottom {
      color: black;
      display: none;
    }
  }
  </style>
  