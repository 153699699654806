<template>
    <router-view/>
</template>

<script>
    import AppFunctions from '../src/helpers/AppFunctions'
    export default {
        mounted() {
            console.log("mounted");
            AppFunctions.toggleClass('body', 'active-light-mode');

        },
    }
</script>

<style>
/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.15);
  transition: color 0.2s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
} */

</style>