<template>
  <Layout header-class="" :show-newsletter="true" :show-cta="false">
    <div
      class="slider-area slider-style-1 height-850 bg_image"
      :style="{
        'background-image': `url(${require(`@/assets/images/services.png`)})`,
        'background-repeat': 'no-repeat',
      }"
      v-show="false"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <!-- <div>
                  <h3
                    class="rn-sub-badge"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <span class="theme-gradient"
                      >产品案例</span
                    >
                  </h3>
                </div> -->
              <!-- <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="color: black; text-align: center"
              >
                支持与服务
              </h1>
              <h4
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-align: center;
                  color: black;
                  font-size: 24px;
                  margin-top: 50px;
                "
              >
                从初期的咨询沟通到需求发掘、方案规划、现场调研、方案设计，再到后期的培训、技术支持等各业务环节，卓能科技建设了完善的制度体系，有效保障优质服务和专业的技术支持。
              </h4> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="slider-area slider-style-1 height-650 bg_image media-img"
      :style="{
        // 'background-image': `url(${require(`@/assets/images/services.png`)})`,
        margin: `0px 0 300px`,
        display: `flex`,
        'flex-direction': `column`,
        position: `relative`,
      }"
    >
      <!-- <h3 style="text-align: center; margin-top: 100px">服务保障</h3> -->

      <div class="service-container">
        <div class="service-container-item">
          <div class="service-container-item-img">
            <img
              class="box media-img-content"
              src="../assets/images/service/sale02.png"
              alt=""
            />
          </div>
          <span class="service-container-item-title">售前服务</span>
          <div class="service-container-item-content">
            <span style="margin-top: 20px">产品咨询</span>
            <span style="margin-top: 20px">需求沟通</span>
          </div>
          <div class="service-container-item-content">
            <span style="margin-top: 20px">案例分享</span>
            <span style="margin-top: 20px">行业经验</span>
          </div>
        </div>

        <div class="service-container-item">
          <div class="service-container-item-img">
            <img
              class="box media-img-content"

              src="../assets/images/service/sale01.png"
              alt=""
            />
          </div>
          <span class="service-container-item-title">售中服务</span>
          <div class="service-container-item-content">
            <span style="margin-top: 20px">需求调研</span>
            <span style="margin-top: 20px">方案设计</span>
          </div>
          <div class="service-container-item-content">
            <span style="margin-top: 20px">系统部署</span>
            <span style="margin-top: 20px">操作培训</span>
          </div>
        </div>

        <div class="service-container-item">
          <div class="service-container-item-img">
            <img
              class="box media-img-content"
              src="../assets/images/service/sale03.png"
              alt=""
            />
          </div>
          <span class="service-container-item-title">售后服务</span>
          <div class="service-container-item-content">
            <span style="margin-top: 20px">7×24小时</span>
            <span style="margin-top: 20px">快速响应</span>
          </div>
          <div class="service-container-item-content">
            <span style="margin-top: 20px">远程维护</span>
            <span style="margin-top: 20px">现场处理</span>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";

import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";

export default {
  name: "Services",
  components: {
    Team,
    Brand,
    Counter,
    Timeline,
    ServiceOne,
    AboutFour,
    SectionTitle,
    Separator,
    Layout,
    ContactForm,
    GoogleMap,
    Icon,
    Breadcrumb,
  },
  data() {
    return {};
  },
  methods: {
    changeStyle(type, e) {
      if (type == "enter") {
        e.currentTarget.className = "enter-active";
      } else if (type == "leave") {
        e.currentTarget.className = "active";
      } else if (type == "enter-detail") {
        e.currentTarget.className = "enter-detail";
      } else if (type == "leave-detail") {
        e.currentTarget.className = "detail";
      } else if (type == "enter-shadow") {
        e.currentTarget.className = "enter-shadow";
      } else if (type == "leave-shadow") {
        e.currentTarget.className = "leave-shadow";
      }
    },
  },
};
</script>

<style scoped>
/* 媒体查询 */
@media (min-width: 768px) {
  /* PC样式 */
  .media-img{
    background-image: url('../assets//images/services.png')
  }
  .media-img-content{
    width: 70px;
    height: 70px;
  }
  .content {
    text-align: center;
    margin-top: 20px;
    color: black;
    font-size: 20px;
  }
  .service-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: center;
    bottom: -20%;
    width: 100%;
  }
  .service-container-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: white;
    width: 15%;
    border-radius: 15px;
    padding: 20px;
    margin: 0 2.5%;
  }
  .service-container-item-img {
    display: flex;
    justify-content: center;
    perspective: 800px; /* 设置透视距离，增加3D效果 */
  }
  .box {
    transition: transform 1s ease-in-out; /* 平滑过渡动画 */
  }
  .service-container-item-img:hover .box {
    transform: rotateY(360deg); /* 沿Y轴旋转360度 */
  }
  .service-container-item-title {
    margin-top: 20px;
    color: black;
    font-size: 20px;
    font-weight: 500;
  }
  .service-container-item-content {
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding: 0 20%;
  }
}

@media (max-width: 767px) {
  /* 移动端样式 */
  .media-img{
  }
  .media-img-content{
    width: 50px;
    height: 50px;
  }
  .content {
    text-align: center;
    margin-top: 20px;
    color: black;
    font-size: 16px;
    padding: 0 10px;
  }
  .service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: -20%;
    width: 90%;
    margin-top: 20px;
  }
  .service-container-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: white;
    width: 80%;
    border-radius: 15px;
    padding: 20px;
    margin: 10px 10%;
  }
  .service-container-item-img {
    display: flex;
    justify-content: center;
  }
  .service-container-item-title {
    margin-top: 20px;
    color: black;
    font-size: 20px;
    font-weight: 500;
  }
  .service-container-item-content {
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding: 0 20%;
  }
}
</style>
